import {type ReactElement, useLayoutEffect} from 'react';
import {useOutletContext} from 'react-router-dom';

import {type LayoutContextType} from '../components/LayoutOutlet';

interface LayoutBase {
	type: Layout['type'];
	title?: ReactElement | string;
}

interface ThreeColumnLayout extends LayoutBase {
	type: 'three-column';
	left?: ReactElement;
	right?: ReactElement;
}

interface WideLayout extends LayoutBase {
	type: 'wide';
}

export type Layout = ThreeColumnLayout | WideLayout;

export const useLayout = (layout: Layout) => {
	const context: LayoutContextType = useOutletContext();

	useLayoutEffect(() => {
		context.setLayout(layout);
	}, [window.location.pathname]);

	const setLayout = (layout: Layout) => {
		context.setLayout(layout);
	};

	return setLayout;
};

export default useLayout;
