import React, {useState} from 'react';
import {Outlet} from 'react-router-dom';
import {Box, Grid} from '@mui/material';

import {type Layout} from '../hooks/useLayout';

export type LayoutContextType = {
	setLayout: (layout: Layout) => void;
	currentLayout: Layout;
};

const mt = 4;
const mb = 6;
const px = 6;
const pt = 4;

export const LayoutOutlet: React.FC = () => {
	const [layout, doSetLayout] = useState<Layout>({
		type: 'three-column',
	});

	const context: LayoutContextType = {
		setLayout(layout: Layout) {
			doSetLayout(layout);
		},
		currentLayout: layout,
	};

	const outlet = <Outlet context={context}/>;

	if (layout.type === 'wide') {
		return (
			<Box sx={{
				mt,
				mb,
				px,
			}}>
				{layout.title}
				{outlet}
			</Box>
		);
	}

	if (layout.type === 'three-column') {
		return (
			<Grid
				container
				sx={{
					mt,
					mb,
					px: {
						xs: undefined,
						lg: 8,
					},
				}}
				spacing={2}
			>
				<Grid item sm={3} sx={{
					display: {
						xs: 'none',
						sm: 'block',
					},
				}}>
					{layout.title}
					{layout.left}
				</Grid>
				<Grid item xs={12} sm={9} lg={6}>
					<Box sx={{
						display: {
							xs: 'block',
							sm: 'none',
						},
					}}>
						{layout.title}
					</Box>
					<Box sx={{mb: pt, display: {xs: 'none', sm: 'block'}}} />
					{outlet}
				</Grid>
				<Grid item sm={3} lg={3} sx={{
					display: {
						xs: 'none',
						sm: 'block',
					},
				}}>
					{layout.right}
				</Grid>
			</Grid>
		);
	}

	console.error('Unknown layout type');

	return outlet;
};

export default LayoutOutlet;
