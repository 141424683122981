import {createContext, useContext} from 'react';

import type ProductType from '../common/types/ProductType';
import type Topic from '../common/types/Topic';
import {type ExpertiseBase} from '../common/types/Expertise';
import type CountryWithRegion from '../common/types/CountryWithRegion';
import {type Attachment, type Product} from '../common/types/Product';

export type Cart = {
	products: Product[];
	current: Partial<Product>;
	addToCart: (product: Product) => void;
	removeFromCart: (product: Product) => void;
	clearCart: () => void;
	setType: (type: ProductType) => void;
	setOtherType: (type: string) => void;
	setPages(fromPages: number, toPages: number): void;
	setCountry(country: CountryWithRegion): void;
	setTopic(topic: Topic): void;
	setOtherTopic(topic: string): void;
	setExpertise(expertise: ExpertiseBase): void;
	setOtherExpertise(expertise: string): void;
	setAdditionalInfo(additionalInfo: string): void;
	setAttachment(attachment: Attachment | undefined): void;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const CartContext = createContext<Cart | undefined>(undefined);

export const builderReady = (cart: Cart) => {
	const {current} = cart;

	return (
		current.type !== undefined
		&& current.fromPages !== undefined
		&& current.toPages !== undefined
		&& current.country !== undefined
		&& current.topic !== undefined
		&& current.expertise !== undefined
		&& (current.type.name !== 'Other' || current.otherType)
		&& (current.topic.name !== 'Other' || current.otherTopic)
		&& (current.expertise.name !== 'Other' || current.otherExpertise)
	);
};

export const useCart = (): Cart => {
	const cart = useContext(CartContext);

	if (cart === undefined) {
		throw new Error('useCart must be used within a CartProvider');
	}

	return cart;
};

export default useCart;
