import React, {useState, useEffect} from 'react';
import {useNavigate, Form, useActionData} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Box, Button, TextField} from '@mui/material';

import {createTitleElement} from '../jsxUtil';
import {type LoginProps} from './Login';
import {type Api} from '../createApi';
import type UserCreateData from '../../common/types/UserCreateData';
import {isError} from '../../common/util';
import {isAcceptablePassword} from '../util';
import useLayout from '../hooks/useLayout';

export type RegisterProps = LoginProps;

export const createAction = (api: Api) => async ({request}: {request: Request}) => {
	const formData = await request.formData();

	const userData: UserCreateData = {
		name: formData.get('name') as string,
		email: formData.get('email') as string,
		password: formData.get('password') as string,
	};

	const confirm = formData.get('confirm') as string;

	if (!userData.email) {
		toast.error('Please fill in your e-mail');
		return null;
	}

	if (!userData.name) {
		toast.error('Please fill in your username');
		return null;
	}

	if (userData.password !== confirm) {
		toast.error('Passwords do not match');
		return null;
	}

	if (!isAcceptablePassword(userData.password)) {
		toast.error('Please use a more secure password');
		return null;
	}

	try {
		const user = await api.createUser(userData);
		return user;
	} catch (e) {
		console.error('create user error', e);
		return null;
	}
};

export const Register: React.FC<RegisterProps> = ({
	email,
	password,
	onEmailChange,
	onPasswordChange,
}) => {
	useLayout({
		type: 'three-column',
		title: undefined,
		left: undefined,
	});

	const navigate = useNavigate();
	const [confirm, setConfirm] = useState('');
	const [username, setUsername] = useState('');

	const actionData = useActionData();

	useEffect(() => {
		if (isError(actionData)) {
			// Automatically handled
		} else if (actionData) {
			toast.success(
				'User created successfully, please validate your e-mail by clicking on the link we just e-mailed to you.',
			);
		}
	}, [actionData]);

	console.log('register action data', actionData);

	const form = (
		<Form method='POST'>
			<TextField
				name='email'
				label='E-mail'
				variant='outlined'
				fullWidth
				required
				autoComplete='email'
				sx={{
					mb: 2,
				}}
				value={email}
				onChange={e => {
					onEmailChange(e.target.value);
				}}
			/>

			<TextField
				name='name'
				label='Username'
				autoComplete='username'
				variant='outlined'
				required
				fullWidth
				sx={{
					mb: 2,
				}}
				value={username}
				onChange={e => {
					setUsername(e.target.value);
				}}
			/>

			<TextField
				name='password'
				label='Password'
				autoComplete='new-password'
				variant='outlined'
				type='password'
				required
				fullWidth
				sx={{
					mb: 2,
				}}
				value={password}
				onChange={e => {
					onPasswordChange(e.target.value);
				}}
			/>

			<TextField
				name='confirm'
				label='Password Confirmation'
				autoComplete='new-password'
				variant='outlined'
				type='password'
				fullWidth
				required
				sx={{
					mb: 2,
				}}
				value={confirm}
				onChange={e => {
					setConfirm(e.target.value);
				}}
			/>

			<Box sx={{
				display: 'flex',
				justifyContent: 'center',
				gap: 2,
			}}>
				<Button
					variant='outlined'
					color='primary'
					onClick={() => {
						navigate('/login');
					}}
				>
					Login instead
				</Button>

				<Button
					variant='contained'
					color='primary'
					type='submit'
				>
					Register
				</Button>
			</Box>
		</Form>
	);

	return (
		<div>
			{createTitleElement('Register')}
			{form}
		</div>
	);
};

export default Register;
