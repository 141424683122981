import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {Box, Button, TextField} from '@mui/material';

import {createFunnelTitleElement} from '../../jsxUtil';
import useApiContext from '../../ApiContext';
import useCart from '../../CartContext';

import type Expertise from '../../../common/types/Expertise';

import UnrolledSelect from '../../components/UnrolledSelect';
import useLayout from '../../hooks/useLayout';
import FunnelRecap from '../../components/FunnelRecap';

export const Funnel5Expertise: React.FC = () => {
	useLayout({
		type: 'three-column',
		title: undefined,
		left: <FunnelRecap />,
	});

	const cart = useCart();
	const {api} = useApiContext();
	const navigate = useNavigate();
	const [expertiseList, setExpertiseList] = useState<Expertise[]>();

	const title = createFunnelTitleElement(
		'What kind of expertise do you need?',
		'5/6 Expertise',
	);

	useEffect(() => {
		(async () => {
			try {
				const e = await api.getExpertiseList(undefined);
				setExpertiseList(e);
			} catch (e) {
				console.error(e);
			}
		})();
	}, []);

	const ui = (
		<Box>
			{title}

			<UnrolledSelect
				items={expertiseList ?? []}
				onSelect={expertise => {
					cart.setExpertise(expertise);
				}}
				selected={cart.current.expertise}
			/>

			{cart.current.expertise?.name === 'Other' && (
				<Box sx={{
					my: 4,
				}}>
					<TextField
						placeholder='Please specify your request (max 500 characters)'
						multiline
						minRows={5}
						style={{
							width: '100%',
							padding: 2,
						}}
						onChange={e => {
							cart.setOtherExpertise(e.target.value);
						}}
						value={cart.current.otherExpertise}
					/>
				</Box>
			)}

			<Box sx={{
				mt: 4,
				display: 'flex',
				justifyContent: 'flex-end',
				gap: 2,
			}}>
				<Button
					variant='text'
					sx={{
						textDecoration: 'underline',
					}}
					color='secondary'
					onClick={
						() => {
							navigate('/topic');
						}
					}
				>
					Back
				</Button>
				<Button
					disabled={!cart.current.expertise}
					variant='contained'
					color='secondary'
					sx={{borderRadius: '20px'}}
					onClick= {
						() => {
							navigate('/additional-info');
						}
					}
				>
					Next
				</Button>
			</Box>
		</Box>
	);

	return ui;
};

export default Funnel5Expertise;
