import React, {useState} from 'react';
import {Search} from '@mui/icons-material';
import {Box, Paper, InputBase, IconButton, Typography} from '@mui/material';

export type Item = {
	id?: number;
	name: string;
};

export const UnrolledSelect: React.FC<{
	items: Item[];
	selected?: Item;
	onSelect?: (item: Item) => void;
}> = ({items, onSelect, selected}) => {
	const [search, setSearch] = useState<string>('');

	const [selectedItem, setSelected] = useState<Item | undefined>(selected);

	const itemMatches = (item: Item) => {
		if (!search) {
			return true;
		}

		if (item.name.toLowerCase().includes(search.toLowerCase())) {
			return true;
		}

		return false;
	};

	const filteredItems = items.filter(itemMatches);

	const ui = (
		<Box>
			{selectedItem && (
				<Box sx={{
					mb: 4,
				}}>
					<Typography>
						<strong>Selected:</strong> {selectedItem.name}
					</Typography>
				</Box>
			)}

			<Box sx={{
				width: {
					xs: '100%',
					sm: '70%',
				},
			}}>
				<Paper
					component='form'
					sx={{
						display: 'flex',
						alignItems: 'center',
						mb: 4,
						boxShadow: 'none',
						borderRadius: '20px',
						backgroundColor: '#ececec',
					}}
				>
					<InputBase
						sx={{ml: 2, flex: 1}}
						placeholder='Search'
						value={search}
						onChange={e => {
							setSearch(e.target.value);
						}}
					/>
					<IconButton type='button' sx={{mr: 1}}>
						<Search />
					</IconButton>
				</Paper>

				<Box>
					<ul>
						{filteredItems.map(item => (
							<li key={item.id}>
								<Typography
									sx={{
										cursor: 'pointer',
									}}
									color={selectedItem?.id === item.id ? 'primary' : undefined}
									onClick={() => {
										setSelected(item);
										onSelect?.(item);
									}}
								>
									{item.name}
								</Typography>
							</li>
						))}
					</ul>
				</Box>
			</Box>
		</Box>
	);

	return ui;
};

export default UnrolledSelect;
