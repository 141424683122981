import React, {useState, useEffect} from 'react';
import {Autocomplete, Box, Button, CircularProgress, TextField, Typography} from '@mui/material';
import {toast} from 'react-toastify';

import {createChevronTitle} from '../jsxUtil';

import StringArrayInput from '../components/StringArrayInput';
import ImageInput from '../components/ImageInput';

import type Language from '../../common/types/Language';
import type Country from '../../common/types/Country';
import type Topic from '../../common/types/Topic';
import type Expertise from '../../common/types/Expertise';

import useApiContext from '../ApiContext';
import {Link} from 'react-router-dom';

import useLayout from '../hooks/useLayout';

export const ExpertProfileForm: React.FC = () => {
	const title = createChevronTitle('Edit your profile');

	useLayout({
		type: 'three-column',
		title,
	});

	const {api} = useApiContext();
	const [isLoading, setIsLoading] = useState(true);

	const [availableLanguages, setAvailableLanguages] = useState<Language[]>([]);
	const [availableCountries, setAvailableCountries] = useState<Country[]>([]);
	const [availableTopics, setAvailableTopics] = useState<Topic[]>([]);
	const [availableExpertise, setAvailableExpertise] = useState<Expertise[]>([]);

	// eslint-disable-next-line @typescript-eslint/ban-types
	const [userIdIfProfileExists, setUserIdIfProfileExists] = useState<number | null>(null);

	/* Start of Expert Profile Fields */
	const [fullName, setFullName] = useState('');
	const [professionalTitle, setProfessionalTitle] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [primaryAffiliation, setPrimaryAffiliation] = useState('');
	const [otherAffiliations, setOtherAffiliations] = useState<string[]>([]);
	const [spokenLanguages, setSpokenLanguages] = useState<Language[]>([]);
	// eslint-disable-next-line @typescript-eslint/ban-types
	const [countryOfResidence, setCountryOfResidence] = useState<Country | null>(null);
	const [cityOfResidence, setCityOfResidence] = useState('');
	// eslint-disable-next-line @typescript-eslint/ban-types
	const [nationalityCountry, setNationalityCountry] = useState<Country | null>(null);
	const [topics, setTopics] = useState<Topic[]>([]);
	const [expertise, setExpertise] = useState<Expertise[]>([]);
	const [professionalExperience, setProfessionalExperience] = useState<string[]>([]);
	const [education, setEducation] = useState<string[]>([]);
	const [certifications, setCertifications] = useState<string[]>([]);
	const [publications, setPublications] = useState<string[]>([]);
	const [awardsAndHonors, setAwardsAndHonors] = useState<string[]>([]);
	const [professionalMemberships, setProfessionalMemberships] = useState<string[]>([]);
	const [patents, setPatents] = useState<string[]>([]);
	const [speakingEngagements, setSpeakingEngagements] = useState<string[]>([]);
	const [clientList, setClientList] = useState<string[]>([]);
	const [testimonials, setTestimonials] = useState<string[]>([]);
	const [availability, setAvailability] = useState<string>('');
	const [timeZone, setTimeZone] = useState<string>('');
	const [linkedInProfile, setLinkedInProfile] = useState<string>('');
	const [researchInterests, setResearchInterests] = useState<string[]>([]);
	const [securityClearance, setSecurityClearance] = useState<string>('');
	const [references, setReferences] = useState<string[]>([]);
	const [portfolio, setPortfolio] = useState<string[]>([]);
	const [imageBase64, setImageBase64] = useState<string>();
	/* End of Expert Profile Fields */

	useEffect(() => {
		(async () => {
			try {
				const ls = await api.getLanguages(undefined);
				setAvailableLanguages(ls);
			} catch (e) {
				console.error(e);
				toast.error('Failed to load languages, something went wrong.');
			}
		})();
	}, []);

	useEffect(() => {
		(async () => {
			try {
				const cs = await api.getCountries(undefined);
				setAvailableCountries(cs);
			} catch (e) {
				console.error(e);
				toast.error('Failed to load countries, something went wrong.');
			}
		})();
	}, []);

	useEffect(() => {
		(async () => {
			try {
				const ts = await api.getTopics(undefined);
				setAvailableTopics(ts);
			} catch (e) {
				console.error(e);
				toast.error('Failed to load topics, something went wrong.');
			}
		})();
	}, []);

	useEffect(() => {
		(async () => {
			try {
				const es = await api.getExpertiseList(undefined);
				setAvailableExpertise(es);
			} catch (e) {
				console.error(e);
				toast.error('Failed to load expertise, something went wrong.');
			}
		})();
	}, []);

	useEffect(() => {
		(async () => {
			try {
				const user = await api.getUser(undefined);
				if (!user.expertProfile) {
					setUserIdIfProfileExists(null);
					return;
				}

				setUserIdIfProfileExists(user.id!);

				const {expertProfile: profile} = user;

				setFullName(profile.fullName);
				setProfessionalTitle(profile.professionalTitle);
				setPhoneNumber(profile.phoneNumber);
				setPrimaryAffiliation(profile.primaryAffiliation);
				setOtherAffiliations(profile.otherAffiliations);
				setSpokenLanguages(profile.languages);
				setCountryOfResidence(profile.countryOfResidence);
				setCityOfResidence(profile.cityOfResidence);
				setNationalityCountry(profile.nationality);
				setTopics(profile.topicsOfFocus);
				setExpertise(profile.areasOfExpertise);
				setProfessionalExperience(profile.professionalExperience);
				setEducation(profile.education);
				setCertifications(profile.certifications);
				setPublications(profile.publications);
				setAwardsAndHonors(profile.awardsAndHonors);
				setProfessionalMemberships(profile.professionalMemberships);
				setPatents(profile.patents);
				setSpeakingEngagements(profile.speakingEngagements);
				setClientList(profile.clientList);
				setTestimonials(profile.testimonials);
				setAvailability(profile.availability);
				setTimeZone(profile.timeZone);
				setLinkedInProfile(profile.linkedInProfile);
				setResearchInterests(profile.researchInterests);
				setSecurityClearance(profile.securityClearance);
				setReferences(profile.references);
				setPortfolio(profile.portfolio);
			} catch (e) {
				console.log('should not be a real problem', e);
			} finally	{
				setIsLoading(false);
			}
		})();
	}, []);

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();

		try {
			const createdAt = new Date();
			const {profile, userId} = await api.createOrUpdateExpertProfile({
				fullName,
				professionalTitle,
				phoneNumber,
				primaryAffiliation,
				otherAffiliations,
				languages: spokenLanguages,
				countryOfResidence: countryOfResidence!,
				cityOfResidence,
				nationality: nationalityCountry!,
				topicsOfFocus: topics,
				areasOfExpertise: expertise,
				professionalExperience,
				education,
				certifications,
				publications,
				awardsAndHonors,
				professionalMemberships,
				patents,
				speakingEngagements,
				clientList,
				testimonials,
				availability,
				timeZone,
				linkedInProfile,
				researchInterests,
				securityClearance,
				references,
				portfolio,
				createdAt,
				updatedAt: createdAt,
				imageBase64,
			});

			setFullName(profile.fullName);
			setProfessionalTitle(profile.professionalTitle);
			setPhoneNumber(profile.phoneNumber);
			setPrimaryAffiliation(profile.primaryAffiliation);
			setOtherAffiliations(profile.otherAffiliations);
			setSpokenLanguages(profile.languages);
			setCountryOfResidence(profile.countryOfResidence);
			setCityOfResidence(profile.cityOfResidence);
			setNationalityCountry(profile.nationality);
			setTopics(profile.topicsOfFocus);
			setExpertise(profile.areasOfExpertise);
			setProfessionalExperience(profile.professionalExperience);
			setEducation(profile.education);
			setCertifications(profile.certifications);
			setPublications(profile.publications);
			setAwardsAndHonors(profile.awardsAndHonors);
			setProfessionalMemberships(profile.professionalMemberships);
			setPatents(profile.patents);
			setSpeakingEngagements(profile.speakingEngagements);
			setClientList(profile.clientList);
			setTestimonials(profile.testimonials);
			setAvailability(profile.availability);
			setTimeZone(profile.timeZone);
			setLinkedInProfile(profile.linkedInProfile);
			setResearchInterests(profile.researchInterests);
			setSecurityClearance(profile.securityClearance);
			setReferences(profile.references);
			setPortfolio(profile.portfolio);

			setUserIdIfProfileExists(userId);

			toast.success('Profile saved successfully!');
		} catch (e) {
			console.error(e);
			const message = e instanceof Error ? e.message : 'Something went wrong while saving profile...';
			toast.error(message);
		}
	};

	if (isLoading) {
		return (
			<Box sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '100%',
			}}>
				<CircularProgress />
			</Box>
		);
	}

	const ui = (
		<Box>
			<Typography sx={{mb: 4}} color='secondary'>
					By completing your profile, you’ll connect with institutions needing complex situational understanding and provide digital-first intelligence briefings and custom policy solutions. <br/>Contribute to impactful projects, from real-time field intelligence to SDG-aligned strategies. Don’t miss the chance to inform better public policy and collaborate with experts worldwide.
			</Typography>
			{userIdIfProfileExists && (
				<Typography sx={{mb: 6}} color='primary'>
					<Link
						to={`/profile/${userIdIfProfileExists}`}
						style={{color: 'inherit', textDecoration: 'underline'}}
					>
						Check out what your profile looks like to others
					</Link>
				</Typography>
			)}

			<Box component='form' onSubmit={handleSubmit}>
				<TextField
					sx={{
						mb: 4,
					}}
					label='Full name'
					fullWidth
					required
					value={fullName}
					onChange={e => {
						setFullName(e.target.value);
					}}
				/>

				<TextField
					sx={{
						mb: 2,
					}}
					label='Professional title'
					fullWidth
					required
					value = {professionalTitle}
					onChange = {e => {
						setProfessionalTitle(e.target.value);
					}}
				/>

				<ImageInput
					label='Profile picture'
					onImageChange={base64 => {
						setImageBase64(base64);
					}}
					sx={{
						mb: 4,
					}}
				/>

				<TextField
					sx={{
						mb: 4,
					}}
					label='Phone number'
					fullWidth
					required
					value={phoneNumber}
					onChange={e => {
						setPhoneNumber(e.target.value);
					}}
				/>

				<TextField
					sx={{
						mb: 4,
					}}
					label='Primary affiliation'
					fullWidth
					required
					value={primaryAffiliation}
					onChange={e => {
						setPrimaryAffiliation(e.target.value);
					}}
				/>

				<StringArrayInput
					sx={{
						mb: 4,
					}}
					label='Other affiliations'
					value={otherAffiliations}
					onChange={setOtherAffiliations}
				/>

				<Autocomplete
					multiple
					options={availableLanguages}
					getOptionLabel={option => option.name}
					isOptionEqualToValue={(option, value) => option.id === value.id}
					value={spokenLanguages}
					onChange={(_event, value) => {
						setSpokenLanguages(value);
					}}
					renderInput={params => (
						<TextField
							{...params}
							label='Spoken languages'
							required={spokenLanguages.length === 0}
						/>
					)}
					sx={{mb: 4}}
				/>

				<Autocomplete
					options={availableCountries}
					getOptionLabel={option => option.name}
					isOptionEqualToValue={(option, value) => option.id === value.id}
					value={countryOfResidence}
					onChange={(_event, value) => {
						setCountryOfResidence(value);
					}}
					renderInput={params => (
						<TextField
							{...params}
							label='Country of residence'
							required
						/>
					)}
					sx={{mb: 4}}
				/>

				<TextField
					sx={{
						mb: 4,
					}}
					label='City of residence'
					fullWidth
					required
					value={cityOfResidence}
					onChange={e => {
						setCityOfResidence(e.target.value);
					}}
				/>

				<Autocomplete
					options={availableCountries}
					getOptionLabel={option => option.name}
					isOptionEqualToValue={(option, value) => option.id === value.id}
					value={nationalityCountry}
					onChange={(_event, value) => {
						setNationalityCountry(value);
					}}
					renderInput={params => (
						<TextField
							{...params}
							label='Country of nationality'
							required
						/>
					)}
					sx={{mb: 4}}
				/>

				<Autocomplete
					multiple
					options={availableExpertise}
					getOptionLabel={option => option.name}
					isOptionEqualToValue={(option, value) => option.id === value.id}
					value={expertise}
					onChange={(_event, value) => {
						setExpertise(value);
					}}
					renderInput={params => (
						<TextField
							{...params}
							label='Areas of expertise'
							required={expertise.length === 0}
						/>
					)}
					sx={{mb: 4}}
				/>

				<Autocomplete
					multiple
					options={availableTopics}
					getOptionLabel={option => option.name}
					isOptionEqualToValue={(option, value) => option.id === value.id}
					value={topics}
					onChange={(_event, value) => {
						setTopics(value);
					}}
					renderInput={params => (
						<TextField
							{...params}
							label='Topics of focus'
							required={topics.length === 0}
						/>
					)}
					sx={{mb: 4}}
				/>

				<TextField
					sx={{
						mb: 4,
					}}
					label='Availability'
					fullWidth
					required
					value={availability}
					onChange={e => {
						setAvailability(e.target.value);
					}}
				/>

				<TextField
					sx={{
						mb: 4,
					}}
					label='Time zone'
					fullWidth
					required
					value={timeZone}
					onChange={e => {
						setTimeZone(e.target.value);
					}}
				/>

				<TextField
					sx={{
						mb: 4,
					}}
					label='LinkedIn profile'
					fullWidth
					value={linkedInProfile}
					onChange={e => {
						setLinkedInProfile(e.target.value);
					}}
				/>

				<TextField
					sx={{
						mb: 4,
					}}
					label='Security clearance'
					fullWidth
					value={securityClearance}
					onChange={e => {
						setSecurityClearance(e.target.value);
					}}
				/>

				<Typography variant='h5' sx={{my: 6}}>Experience (Optional) </Typography>

				<StringArrayInput
					sx={{
						mb: 4,
					}}
					label='Professional experience'
					value={professionalExperience}
					onChange={setProfessionalExperience}
				/>

				<StringArrayInput
					sx={{
						mb: 4,
					}}
					label='Education'
					value={education}
					onChange={setEducation}
				/>

				<StringArrayInput
					sx={{
						mb: 4,
					}}
					label='Certifications'
					value={certifications}
					onChange={setCertifications}
				/>

				<StringArrayInput
					sx={{
						mb: 4,
					}}
					label='Publications'
					value={publications}
					onChange={setPublications}
				/>

				<StringArrayInput
					sx={{
						mb: 4,
					}}
					label='Awards and honors'
					value={awardsAndHonors}
					onChange={setAwardsAndHonors}
				/>

				<StringArrayInput
					sx={{
						mb: 4,
					}}
					label='Professional memberships'
					value={professionalMemberships}
					onChange={setProfessionalMemberships}
				/>

				<StringArrayInput
					sx={{
						mb: 4,
					}}
					label='Patents'
					value={patents}
					onChange={setPatents}
				/>

				<StringArrayInput
					sx={{
						mb: 4,
					}}
					label='Speaking engagements'
					value={speakingEngagements}
					onChange={setSpeakingEngagements}
				/>

				<StringArrayInput
					sx={{
						mb: 4,
					}}
					label='Client list'
					value={clientList}
					onChange={setClientList}
				/>

				<StringArrayInput
					sx={{
						mb: 4,
					}}
					label='Testimonials'
					value={testimonials}
					onChange={setTestimonials}
				/>

				<StringArrayInput
					sx={{
						mb: 4,
					}}
					label='Research interests'
					value={researchInterests}
					onChange={setResearchInterests}
				/>

				<StringArrayInput
					sx={{
						mb: 4,
					}}
					label='References'
					value={references}
					onChange={setReferences}
				/>

				<StringArrayInput
					sx={{
						mb: 4,
					}}
					label='Portfolio'
					value={portfolio}
					onChange={setPortfolio}
				/>

				<Box sx={{mt: 4, textAlign: 'center'}}>
					<Button type='submit' variant='contained'>Save profile</Button>
				</Box>
			</Box>
		</Box>
	);

	return ui;
};

export default ExpertProfileForm;
