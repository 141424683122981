import {TextField, Button, Box} from '@mui/material';
import React from 'react';
import {createTitleElement} from '../jsxUtil';
import {useNavigate} from 'react-router-dom';
import useApiContext from '../ApiContext';
import {toast} from 'react-toastify';
import {getMessage} from '../../common/util';
import useLayout from '../hooks/useLayout';

export type ForgotPasswordProps = {
	email: string;
	onEmailChange: (email: string) => void;
};

const ForgotPassword: React.FC<ForgotPasswordProps> = ({email, onEmailChange}) => {
	useLayout({
		type: 'three-column',
		title: undefined,
		left: undefined,
	});

	const title = createTitleElement('Reset your password');
	const navigate = useNavigate();
	const {api} = useApiContext();

	const ui = (
		<Box>
			{title}

			<Box component='form' method='POST' onSubmit={async e => {
				e.preventDefault();

				try {
					await api.resetPasswordSendEmail({email});
					toast.success('Please check your e-mail to reset your password');
				} catch (e) {
					toast.error(getMessage(e, 'Sorry, something unexpected happened...'));
				}
			}}>
				<TextField
					label='E-mail or username'
					name='email'
					autoComplete='username email'
					value={email}
					onChange={e => {
						onEmailChange(e.target.value);
					}}
					sx={{
						mb: 2,
					}}
					fullWidth
					required
				/>
				<Box sx={{
					display: 'flex',
					justifyContent: 'center',
					gap: 2,
				}}>
					<Button variant='outlined' onClick={() => {
						navigate('/login');
					}}>
						Back to Login
					</Button>
					<Button variant='contained' color='primary' type='submit'>
						Send reset password e&#x2011;mail
					</Button>
				</Box>
			</Box>
		</Box>
	);

	return ui;
};

export default ForgotPassword;
