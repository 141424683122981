import React, {useState, useEffect} from 'react';
import {type SxProps, Box, Typography} from '@mui/material';

import {toast} from 'react-toastify';

import {encode} from 'base64-arraybuffer';

import {MuiFileInput} from 'mui-file-input';

export const ImageInput: React.FC<{
	onImageChange: (imageBase64?: string) => void;
	label: string;
	sx?: SxProps;
	previewSx?: SxProps;
	inputSx?: SxProps;
}> = ({
	label,
	onImageChange,
	sx,
	previewSx = {},
	inputSx = {},
}) => {
	const [file, setFile] = useState<File | undefined>();
	const [dataUrl, setDataUrl] = useState<string>();
	const [imageBase64, setImageBase64] = useState<string>();

	useEffect(() => {
		if (!file) {
			setDataUrl(undefined);
			setImageBase64(undefined);
			onImageChange(undefined);
			return;
		}

		const dataUrlReader = new FileReader();
		const arrayBufferReader = new FileReader();

		const dataUrlReaderOnLoad = () => {
			setDataUrl(dataUrlReader?.result?.toString() ?? '');
		};

		const dataUrlReaderOnError = () => {
			toast.error('Failed to load image preview.');
		};

		dataUrlReader.addEventListener('load', dataUrlReaderOnLoad);
		dataUrlReader.addEventListener('error', dataUrlReaderOnError);

		const arrayBufferReaderOnload = () => {
			const imageData = arrayBufferReader.result;

			if (!(imageData instanceof ArrayBuffer)) {
				console.error('Image data is not an array buffer.');
				return;
			}

			setImageBase64(encode(imageData));
		};

		const arrayBufferReaderOnError = () => {
			toast.error('Failed to load image data.');
		};

		arrayBufferReader.addEventListener('load', arrayBufferReaderOnload);
		arrayBufferReader.addEventListener('error', arrayBufferReaderOnError);

		dataUrlReader.readAsDataURL(file);
		arrayBufferReader.readAsArrayBuffer(file);

		return () => {
			dataUrlReader.removeEventListener('load', dataUrlReaderOnLoad);
			dataUrlReader.removeEventListener('error', dataUrlReaderOnError);
			arrayBufferReader.removeEventListener('load', arrayBufferReaderOnload);
			arrayBufferReader.removeEventListener('error', arrayBufferReaderOnError);
		};
	}, [file]);

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
		onImageChange(imageBase64 || undefined);
	}, [imageBase64]);

	// eslint-disable-next-line @typescript-eslint/ban-types
	const onChange = (v: File | null) => {
		setFile(v ?? undefined);
	};

	return (
		<Box sx={sx}>
			<MuiFileInput
				label={label}
				onChange={onChange}
				value={file} sx={{
					my: 2,
					display: 'block',
					...inputSx,
				}}
				inputProps={{
					accept: 'image/png, image/gif, image/jpeg, image/webp, image/svg',
				}}
				fullWidth
			/>
			{dataUrl && (
				<Box sx={{
					my: 2,
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'space-evenly',
					...previewSx,
				}}>
					<Typography component='strong' sx={{
						display: 'block',
						my: 2,
					}}>
						Logo Preview
					</Typography>
					<img
						src={dataUrl}
						alt='preview'
						style={{maxWidth: '30%'}}
					/>
				</Box>
			)}
		</Box>
	);
};

export default ImageInput;

