import React from 'react';

import {Box} from '@mui/material';

import Funnel1ProductType from './funnel/Funnel-1-ProductType';
import useLayout from '../hooks/useLayout';
import FunnelRecap from '../components/FunnelRecap';

export const Home: React.FC = () => {
	useLayout({
		type: 'three-column',
		title: undefined,
		left: <FunnelRecap />,
	});

	const ui = (
		<Box>
			<Funnel1ProductType />
		</Box>
	);

	return ui;
};

export default Home;
