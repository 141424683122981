import React from 'react';
import {useNavigate} from 'react-router-dom';

import {Box, Typography, Chip} from '@mui/material';
import {Check as CheckIcon} from '@mui/icons-material';

import {useCart} from '../CartContext';

export const FunnelRecap = () => {
	const {current} = useCart();
	const navigate = useNavigate();

	const ui = (
		<Box>
			<Typography
				sx={{
					mb: 4,
					mr: {
						xs: undefined,
						lg: 12,
					}}
				}
				color='text.primary'
			>
				<strong>Need local insights fast?<br/></strong>Request on-demand intelligence from independent local advisories now and we will put you in contact with the right people&nbsp;
			</Typography>

			{current.type && (
				<Box sx={{mb: 2, cursor: 'pointer'}} onClick={() => {
					navigate('/');
				}}>
					<Typography>
						<strong>Type</strong>
					</Typography>
					<Typography color='primary'>
						{current.type.name}
					</Typography>
				</Box>
			)}

			{current.fromPages && current.toPages && (
				<Box sx={{mb: 2, cursor: 'pointer'}} onClick={() => {
					navigate('/product-length');
				}}>
					<Typography>
						<strong>Length</strong>
					</Typography>
					<Typography color='primary'>
						{current.fromPages}&rarr;{current.toPages}
					</Typography>
				</Box>
			)}

			{current.country && (
				<Box sx={{mb: 2, cursor: 'pointer'}} onClick={() => {
					navigate('/country');
				}}>
					<Typography>
						<strong>Country</strong>
					</Typography>
					<Typography color='primary'>
						{current.country.country_name}
					</Typography>
				</Box>
			)}

			{current.topic && (
				<Box sx={{mb: 2, cursor: 'pointer'}} onClick={() => {
					navigate('/topic');
				}}>
					<Typography>
						<strong>Topic</strong>
					</Typography>
					<Typography color='primary'>
						{current.topic.name}
					</Typography>
				</Box>
			)}

			{current.expertise && (
				<Box sx={{mb: 2, cursor: 'pointer'}} onClick={() => {
					navigate('/expertise');
				}}>
					<Typography>
						<strong>Expertise</strong>
					</Typography>
					<Typography color='primary'>
						{current.expertise.name}
					</Typography>
				</Box>
			)}

			{current.expertise && (
				<Box sx={{mb: 2, cursor: 'pointer'}} onClick={() => {
					navigate('/additional-info');
				}}>
					<Typography>
						<strong>Additional info</strong>
					</Typography>

					{current.additionalInfo && (
						<Chip
							icon={<CheckIcon />}
							label='Description'
							color='primary'
							variant='outlined'
							sx={{
								mt: 1,
								mr: 1,
							}}
							size='small'
						/>
					)}
					{current.attachment && (
						<Chip
							sx={{
								mt: 1,
							}}
							icon={<CheckIcon />}
							label='Attachment'
							color='primary'
							variant='outlined'
							size='small'
						/>
					)}
				</Box>
			)}
		</Box>
	);

	return ui;
};

export default FunnelRecap;
