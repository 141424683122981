import React from 'react';
import {Link} from 'react-router-dom';
import PlaceIcon from '@mui/icons-material/Place';

import SubjectIcon from '@mui/icons-material/Subject';
import EngineeringIcon from '@mui/icons-material/Engineering';

import {
	Grid,
	Button,
	Card,
	CardContent,
	Typography,
	CardActions,
	Stack,
	Chip,
	List,
	Divider,
} from '@mui/material';

import {type Org} from '../../common/types/Org';
import Image from './Image';

export const OrgCard: React.FC<{
	org: Org;
}> = ({org}) => {
	console.log('org', org);

	const ui = (
		<Card sx={{
			backgroundColor: '#ececec',
			borderRadius: '20px',
			boxShadow: 0,
		}}>
			<Grid container>
				<Grid item xs={12} sm={4} sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					p: 1,
					backgroundColor: '#fff',
					border: '1px solid',
					borderTopLeftRadius: {
						xs: '20px',
						sm: '20px',
					},
					borderBottomLeftRadius: {
						xs: '0px',
						sm: '20px',
					},
					borderTopRightRadius: {
						xs: '20px',
						sm: '0',
					},
				}}>
					<Image sources={org.image} style={{
						maxWidth: '100%',
						// D height: 'auto',
					}}/>
				</Grid>
				<Grid item xs={12} sm={8}>
					<CardContent>
						<Typography variant='h5' sx={{mb: 1}}>
							<strong>{org.name}</strong>
						</Typography>
						<Stack alignItems='center' direction='row' gap={1}>
							<PlaceIcon fontSize='small' color='primary' />
							<Typography variant='body2'>
								{org.countries.map(o => o.name).join(', ')}
							</Typography>
						</Stack>
						<Typography variant='body1' sx={{my: 2}}>
							{org.description}
						</Typography>
						{org.expertise.length > 0 && (
							<List>
								{org.expertise.map(ex => (
									<Chip key={ex.id} icon={<EngineeringIcon />} label={ex.name} sx={{my: 1, mx: 1}} color='primary'/>
									// <li key={t.id}><Typography variant='body2'>{t.name}</Typography></li>
								))}
							</List>
						)}
						<Divider />
						{org.topics.length > 0 && (
							<List>
								{org.topics.map(t => (
									<Chip key={t.id} icon={<SubjectIcon />} label={t.name} sx={{my: 1, mx: 1}} color='secondary'/>
									// <li key={t.id}><Typography variant='body2'>{t.name}</Typography></li>
								))}
							</List>
						)}
					</CardContent>
					<CardActions sx={{
						display: 'flex',
						justifyContent: 'flex-end',
						p: 2,
					}}>
						<Link
							to={`/orgs/${org.id}`}
						>
							<Button
								size='small'
								color='primary'
								variant='contained'
								type='button'
								sx={{
									borderRadius: '20px',
								}}
							>
								More info
							</Button>
						</Link>
					</CardActions>
				</Grid>
			</Grid>
		</Card>
	);

	return ui;
};

export default OrgCard;
