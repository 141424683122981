import React from 'react';

import {
	Box,
	Typography,
} from '@mui/material';

import {createTitleElement} from '../jsxUtil';
import useLayout from '../hooks/useLayout';

export const NotFound: React.FC = () => {
	useLayout({
		type: 'three-column',
		title: undefined,
		left: undefined,
	});

	const title = createTitleElement('Oops...');

	return (
		<Box>
			{title}
			<Typography variant='body1'>
				This page doesn&apos;t exist (yet?), sorry about that!
			</Typography>
		</Box>
	);
};

export default NotFound;

