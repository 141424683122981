import {type CoopBase} from './Coop';

import {IsString, IsNotEmpty, IsOptional} from 'class-validator';

export class CoopCreateData implements Partial<CoopBase> {
	@IsString()
	@IsNotEmpty()
		name: string = '';

	@IsString()
	@IsNotEmpty()
		description: string = '';

	@IsString()
	@IsOptional()
		logoBase64?: string;
}

export default CoopCreateData;
