import React, {useState, useEffect} from 'react';

import {
	Autocomplete,
	Box,
	Button,
	InputLabel,
	TextareaAutosize,
	TextField,
	Typography,
} from '@mui/material';

import type Org from '../../common/types/Org';
import useApiContext from '../ApiContext';
import type Expertise from '../../common/types/Expertise';
import type Country from '../../common/types/Country';
import type Topic from '../../common/types/Topic';

import ImageInput from './ImageInput';
import {type OrgFormEditData} from '../../common/types/Org';

export const OrgForm: React.FC<{
	title?: string;
	buttonText?: string;
	subtitle?: string;
	org: Org;
	handleSubmit: (org: OrgFormEditData) => void;
	extraButtons?: React.ReactElement[];
}> = ({
	org,
	handleSubmit,
	buttonText,
	title,
	subtitle,
	extraButtons,
}) => {
	const [name, setName] = useState(org.name);
	const [description, setDescription] = useState(org.description);
	const [topics, setTopics] = useState(org.topics);
	const [expertise, setExpertise] = useState(org.expertise);
	const [countries, setCountries] = useState(org.countries);
	const [imageBase64, setImageBase64] = useState<string>();
	const {api} = useApiContext();

	const [availableTopics, setAvailableTopics] = useState<Topic[]>([]);
	const [availableExpertise, setAvailableExpertise] = useState<Expertise[]>([]);
	const [availableCountries, setAvailableCountries] = useState<Country[]>([]);

	useEffect(() => {
		setName(org.name);
		setDescription(org.description);
		setTopics(org.topics);
		setExpertise(org.expertise);
		setCountries(org.countries);
	}, [org]);

	useEffect(() => {
		(async () => {
			const dbTopicsP = api.getTopics(undefined);
			const dbExpertiseP = api.getExpertiseList(undefined);
			const dbCountriesP = api.getCountries(undefined);

			const [dbTopics, dbExpertise, dbCountries] = await Promise.all([
				dbTopicsP,
				dbExpertiseP,
				dbCountriesP,
			]);

			setAvailableTopics(dbTopics);
			setAvailableExpertise(dbExpertise);
			setAvailableCountries(dbCountries);
		})();
	}, []);

	const onSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		const updatedOrg: OrgFormEditData = {
			...org,
			name,
			description,
			topics,
			expertise,
			countries,
			imageBase64,
		};

		handleSubmit(updatedOrg);
	};

	return (
		<Box component='form' onSubmit={onSubmit}>
			{title && (<Typography variant='h6'>
				{title}
			</Typography>)}
			{subtitle && (
				<Typography variant='body1'>
					{subtitle}
				</Typography>
			)}
			<TextField
				label='Name'
				value={name}
				onChange={e => {
					setName(e.target.value);
				}}
				fullWidth
				sx={{mt: 2}}
			/>
			<InputLabel sx={{mt: 2}}>
				Description<br />
				<TextareaAutosize
					value={description}
					onChange={e => {
						setDescription(e.target.value);
					}}
					style={{width: '100%'}}
					minRows={4}
				/>
			</InputLabel>
			<ImageInput
				label='Organization logo or illustration'
				onImageChange={data => {
					setImageBase64(data);
				}}
			/>
			<Autocomplete
				multiple
				options={availableCountries}
				getOptionLabel={option => option.name}
				value={countries}
				isOptionEqualToValue={(option, value) => option.a3Code === value.a3Code}
				onChange={(_event, value) => {
					setCountries(value);
				}}
				renderInput={params => (
					<TextField
						{...params}
						label='Countries'
					/>
				)}
				sx={{mt: 2}}
			/>
			<Autocomplete
				multiple
				options={availableExpertise}
				getOptionLabel={option => option.name}
				isOptionEqualToValue={(option, value) => option.id === value.id}
				value={expertise}
				onChange={(_event, value) => {
					setExpertise(value);
				}}
				renderInput={params => (
					<TextField
						{...params}
						label='Expertise'
					/>
				)}
				sx={{mt: 2}}
			/>
			<Autocomplete
				multiple
				options={availableTopics}
				getOptionLabel={option => option.name}
				isOptionEqualToValue={(option, value) => option.id === value.id}
				value={topics}
				onChange={(_event, value) => {
					setTopics(value);
				}}
				renderInput={params => (
					<TextField
						{...params}
						label='Topics'
					/>
				)}
				sx={{mt: 2}}
			/>
			<Box sx={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'flex-start',
				alignItems: 'center',
				gap: 2,
				my: 2,
			}}>
				{...(extraButtons ?? [])}
				<Button
					type='submit'
					variant='contained'
					color='primary'
				>
					{buttonText ?? 'Submit Organization'}
				</Button>
			</Box>
		</Box>
	);
};

export default OrgForm;
