import React, {useState, useEffect} from 'react';

import {Box, Button, IconButton, TextField, Typography} from '@mui/material';
import {Delete as DeleteIcon} from '@mui/icons-material';
import {MuiFileInput} from 'mui-file-input';

import {encode} from 'base64-arraybuffer';

import {createFunnelTitleElement} from '../../jsxUtil';
import {useNavigate} from 'react-router-dom';
import useCart, {builderReady} from '../../CartContext';
import type Product from '../../../common/types/Product';
import {getId} from '../../util';
import {toast} from 'react-toastify';
import useLayout from '../../hooks/useLayout';

import FunnelRecap from '../../components/FunnelRecap';

const Funnel6AdditionalInfo: React.FC = () => {
	useLayout({
		type: 'three-column',
		title: undefined,
		left: <FunnelRecap />,
	});

	const cart = useCart();
	const navigate = useNavigate();
	// eslint-disable-next-line @typescript-eslint/ban-types
	const [attachedFile, setAttachedFile] = useState<File | null>(null);

	const maxFileSizeMb = 5;

	const readyToPlaceRequest = builderReady(cart);

	const product: Product | undefined = builderReady(cart) ? {
		id: getId(),
		type: cart.current.type!,
		fromPages: cart.current.fromPages!,
		toPages: cart.current.toPages!,
		country: cart.current.country!,
		topic: cart.current.topic!,
		expertise: cart.current.expertise!,
		otherType: cart.current.otherType,
		otherTopic: cart.current.otherTopic,
		otherExpertise: cart.current.otherExpertise,
		additionalInfo: cart.current.additionalInfo,
		attachment: cart.current.attachment,
	} : undefined;

	// eslint-disable-next-line @typescript-eslint/ban-types
	const onChange = (file: File | null) => {
		if (file) {
			const {size} = file;

			if (size > maxFileSizeMb * 1024 * 1024) {
				toast.error(`File size must be less than ${maxFileSizeMb} MB.`);
				return;
			}
		}

		setAttachedFile(file);
	};

	useEffect(() => {
		if (!attachedFile) {
			return;
		}

		const arrayBufferReader = new FileReader();

		const arrayBufferReaderOnload = () => {
			const data = arrayBufferReader.result;

			if (!(data instanceof ArrayBuffer)) {
				console.error('data is not an array buffer.');
				return;
			}

			cart.setAttachment({
				dataBase64: encode(data),
				name: attachedFile.name,
			});
		};

		const arrayBufferReaderOnError = () => {
			toast.error('Failed to load image data.');
		};

		arrayBufferReader.addEventListener('load', arrayBufferReaderOnload);
		arrayBufferReader.addEventListener('error', arrayBufferReaderOnError);

		arrayBufferReader.readAsArrayBuffer(attachedFile);

		return () => {
			arrayBufferReader.removeEventListener('load', arrayBufferReaderOnload);
			arrayBufferReader.removeEventListener('error', arrayBufferReaderOnError);
		};
	}, [attachedFile]);

	const title = createFunnelTitleElement(
		'Provide additional information',
		'6/6 More info',
	);

	const ui = (
		<Box>
			{title}

			<Typography sx={{mb: 2}}>
				<strong>This is a beta version of the Index.</strong>
			</Typography>

			<Typography sx={{mb: 4}}>
				There isn&apos;t an expert in the Index yet who can respond to this briefing.
				Polylat staff will handle the request internally.
			</Typography>

			<TextField
				minRows={10}
				fullWidth
				multiline
				placeholder='Add a description of your request (optional)'
				onChange={e => {
					cart.setAdditionalInfo(e.target.value);
				}}
				value={cart.current.additionalInfo ?? ''}
			/>

			{(!cart.current.attachment) && (
				<MuiFileInput
					label='Attach a file (optional)'
					onChange={onChange}
					value={attachedFile}
					sx={{
						my: 2,
						display: 'block',
					}}
					fullWidth
					multiple={false}
				/>
			)}

			{cart.current.attachment && (
				<Box>
					<Typography sx={{
						mt: 2,
						mb: 0,
					}}>
						<strong>Attachment</strong>
					</Typography>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
							gap: 2,
							mb: 2,
						}}
					>
						<Typography>
							{cart.current.attachment.name}
						</Typography>
						<IconButton
							onClick={() => {
								cart.setAttachment(undefined);
								setAttachedFile(null);
							}}
						>
							<DeleteIcon />
						</IconButton>
					</Box>
				</Box>
			)}

			<Box sx={{
				mt: 4,
				display: 'flex',
				justifyContent: 'flex-end',
				gap: 2,
			}}>
				<Button
					variant='text'
					sx={{
						textDecoration: 'underline',
					}}
					color='secondary'
					onClick={
						() => {
							navigate('/expertise');
						}
					}
				>
					Back
				</Button>
				<Button
					variant='contained'
					color='primary'
					sx={{borderRadius: '20px'}}
					type='button'
					disabled={!readyToPlaceRequest}
					onClick={() => {
						if (product) {
							cart.addToCart(product);
							navigate('/requests');
						}
					}}
				>
					Send a request
				</Button>
			</Box>
		</Box>
	);

	return ui;
};

export default Funnel6AdditionalInfo;
