import type Model from './Model';
import type Country from './Country';

import type Expertise from './Expertise';
import type Topic from './Topic';
import type ImageSourceList from './ImageSourceList';
import type User from './User';
import type OrgToUser from './OrgToUser';

export interface Org extends Model {
	name: string;
	description: string;
	expertise: Expertise[];
	countries: Country[];
	topics: Topic[];
	image?: ImageSourceList;
	owner?: User;
	members: OrgToUser[];
}

export interface OrgFormData extends Partial<Org> {
	imageBase64?: string;
}

export interface OrgFormEditData extends Partial<OrgFormData> {
	id?: number;
}

export const createOrg = (): Org => ({
	id: 0,
	name: '',
	description: '',
	topics: [],
	expertise: [],
	countries: [],
	createdAt: new Date(),
	updatedAt: new Date(),
	members: [],
});

export default Org;
