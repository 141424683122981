import React, {useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {TextField, Button, Box, Typography} from '@mui/material';
import {toast} from 'react-toastify';

import {createTitleElement} from '../jsxUtil';
import {useNavigate} from 'react-router-dom';
import useApiContext from '../ApiContext';
import {getMessage} from '../../common/util';
import {isAcceptablePassword} from '../util';
import useLayout from '../hooks/useLayout';

const SetNewPassword: React.FC = () => {
	useLayout({
		type: 'three-column',
		title: undefined,
		left: undefined,
	});

	const title = createTitleElement('Set your new password');
	const navigate = useNavigate();
	const {api} = useApiContext();

	const [password, setPassword] = useState('');
	const [confirm, setConfirm] = useState('');
	const [searchParams] = useSearchParams();

	const token = searchParams.get('token');

	if (!token) {
		return (
			<Box>
				{title}

				<Box>
					<Typography>
						No token provided
					</Typography>
				</Box>
			</Box>
		);
	}

	const ui = (
		<Box>
			{title}

			<Box component='form' method='POST' onSubmit={async e => {
				e.preventDefault();

				if (password !== confirm) {
					toast.error('Passwords do not match');
					return;
				}

				if (!isAcceptablePassword(password)) {
					toast.error('Please use a more secure password');
					return;
				}

				try {
					await api.resetPasswordSetNew({token, password});
					navigate(`/login?message=${encodeURIComponent('Password reset successfully, you can now log in')}`);
				} catch (e) {
					const msg = getMessage(e, 'Sorry, something unexpected happened...');
					toast.error(msg);
				}
			}}>
				<TextField
					label='Your new password'
					name='password'
					autoComplete='password'
					type='password'
					value={password}
					onChange={e => {
						setPassword(e.target.value);
					}}
					sx={{
						mb: 2,
					}}
					fullWidth
					required
				/>
				<TextField
					label='Confirm your new password'
					name='confirm'
					type='password'
					autoComplete='confirm'
					value={confirm}
					onChange={e => {
						setConfirm(e.target.value);
					}}
					sx={{
						mb: 2,
					}}
					fullWidth
					required
				/>
				<Box sx={{
					display: 'flex',
					justifyContent: 'center',
					gap: 2,
				}}>
					<Button variant='contained' color='primary' type='submit'>
						Reset password
					</Button>
				</Box>
			</Box>
		</Box>
	);

	return ui;
};

export default SetNewPassword;
