import React, {useEffect} from 'react';
import {useLoaderData} from 'react-router-dom';
import {toast} from 'react-toastify';

import {Grid, Box, Typography} from '@mui/material';

import type Coop from '../../common/types/Coop';

import {createTitleElement} from '../jsxUtil';
import {isError, isNotError} from '../util';

import {type Api} from '../createApi';
import useApiContext from '../ApiContext';

import CoopForm from '../components/CoopForm';
import CoopCard from '../components/CoopCard';
import useLayout from '../hooks/useLayout';

export const createLoader = (api: Api) => async () => api.getCoopsList(undefined);

const List: React.FC<{
	coops: Coop[];
}> = ({coops}) =>
	<Grid container spacing={4}>
		{coops.map(coop => (
			<Grid item xs={12} md={6} lg={4} key={coop.id}>
				<CoopCard key={coop.id} coop={coop} />
			</Grid>
		))}
	</Grid>;

export const CoopsList: React.FC = () => {
	useLayout({
		type: 'three-column',
		title: undefined,
		left: undefined,
	});

	const title = createTitleElement('Our Cooperatives');
	const data = useLoaderData();
	const {api} = useApiContext();

	useEffect(() => {
		if (isError(data)) {
			toast.error(data.message);
		}
	}, [data]);

	if (!isNotError<Coop[]>(data)) {
		return (
			<Box>
				{title}
				<Typography variant='body1' sx={{textAlign: 'center'}}>
					Failed to load cooperatives.
				</Typography>
			</Box>
		);
	}

	if (data.length === 0) {
		return (
			<Box>
				{title}
				<Typography variant='body1' sx={{textAlign: 'center'}}>
					No cooperatives found.
				</Typography>
				{api.userIsSuperAdmin() && <CoopForm />}
			</Box>
		);
	}

	return (
		<Box>
			{title}
			<List coops={data} />
			{api.userIsSuperAdmin() && <Box sx={{
				mt: 4,
			}}>
				<CoopForm />
			</Box>}
		</Box>
	);
};

export default CoopsList;
