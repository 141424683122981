import React, {useEffect} from 'react';
import {useLoaderData, /* D useLocation, */ useSearchParams} from 'react-router-dom';

import {type Api} from '../createApi';

import OrgCard from '../components/OrgCard';
import OrgFilter from '../components/OrgFilter';
import type OrgsQuery from '../../common/types/OrgsQuery';
import {
	type Org,
} from '../../common/types/Org';

import {
	Box,
	Grid,
	Typography,
} from '@mui/material';

import type OrgsReply from '../../common/types/OrgsReply';

import {createChevronTitle} from '../jsxUtil';
import useLayout from '../hooks/useLayout';

type Props = {
	reply: OrgsReply;
	initialQuery: OrgsQuery;
};

// eslint-disable-next-line @typescript-eslint/ban-types
const intList = (str: string | null): number[] => {
	if (!str) {
		return [];
	}

	const parsed = str.split(',').map(Number);

	if (parsed.some(isNaN)) {
		return [];
	}

	return parsed;
};

export const createLoader = (api: Api) => async ({request}: {request: Request}): Promise<Props> => {
	const url = new URL(request.url);
	const params = url.searchParams;

	const query = {
		expertiseId: intList(params.get('eid')),
		countryId: intList(params.get('cid')),
	};

	return {
		reply: api.userIsVerified() ? await api.filterOrgs(query) : {
			expertise: [],
			country: [],
			orgs: [],
		},
		initialQuery: query,
	};
};

export const OrgsList: React.FC = () => {
	const title = createChevronTitle('Find an Organization');

	useLayout({
		type: 'three-column',
		title,
		left: undefined,
	});

	const {
		reply: {orgs, expertise, country},
		initialQuery,
	} = useLoaderData() as Props;

	const [orgsList, setOrgsList] = React.useState<Org[]>(orgs);

	const [, setSearchParams] = useSearchParams();

	useEffect(() => {
		setOrgsList(orgs);
	}, [orgs]);

	const onChange = (query: OrgsQuery) => {
		setSearchParams({
			eid: query.expertiseId.join(','),
			cid: query.countryId.join(','),
		});
	};

	const filter = (
		<OrgFilter {...{expertise, country, onChange, initialQuery}} />
	);

	if (orgs.length === 0) {
		return (
			<Box>
				{filter}
				<Typography variant='body1'>No organizations found. Log-in to access the network.</Typography>
			</Box>
		);
	}

	return (
		<Box>
			{filter}
			<Grid container spacing={4}>
				{orgsList.map(org => (
					<Grid item xs={12} key={org.id}>
						<OrgCard org={org} />
					</Grid>
				))}
			</Grid>
		</Box>
	);
};

export default OrgsList;
