	import React from 'react';

import {useNavigate} from 'react-router-dom';

import {
	Box,
	Typography,
	Divider,
	Button,
} from '@mui/material';

import {createSubtitleElement, createChevronTitle} from '../jsxUtil';
import useLayout from '../hooks/useLayout';

export const About: React.FC = () => {
	const title = createChevronTitle('What is Polylat Index');

	const navigate = useNavigate();

	useLayout({
		type: 'three-column',
		title,
		left: undefined,
	});

	return (
		<Box>
			{createSubtitleElement('Changing the Way Knowledge is Distributed')}
			<Typography sx={{mb: 5}}>
			At our core, we aim to revolutionize the way knowledge is shared across the globe. The international system is plagued by critical knowledge gaps, hindering effective decision-making and policy development. We are dedicated to bridging these gaps through an innovative, independent index of local knowledge-producing organizations.
			</Typography>
			{createSubtitleElement('Our Mission')}
			<Typography sx={{mb: 5}}>
			We connect institutions that require complex situational understanding with local, in-country organizations that hold vital knowledge. By doing so, we provide digital-first intelligence briefings and custom policy innovation and implementation projects tailored for NGOs, UN agencies, public institutions, and more.
			</Typography>
			<Divider sx={{mb:5}}/>
			{createSubtitleElement('What We Offer')}
			<Typography color='primary' variant='h6' sx={{mb: 3}}><strong>On-Demand Knowledge at Your Fingertips</strong></Typography>
			<Typography sx={{mb: 5}}>
			Our platform allows you to join a global network of distributed, in-situ specialists. These experts offer fast, objective, and trusted sources of truth, helping you make informed decisions swiftly and confidently. In the future, you will also be able to request advisory services directly from them. We strive to be your one-stop shop for distributed intelligence, particularly to inform better public policy for and in the Global South.			</Typography>
			<Typography color='primary' variant='h6' sx={{mb: 3}}><strong>Specific Document Requests</strong></Typography>
			<Typography sx={{mb: 5}}>
			Whether you need detailed reports, policy briefs, research papers, or any other specialized documents, our team at Polylat is ready to deliver high-quality, tailored content to meet your needs. Each document is crafted with precision and expertise, ensuring that it meets the highest standards of quality and relevance. By leveraging our network, you gain access to specialized knowledge and insights that can significantly enhance your projects and initiatives. Trust Polylat to provide the comprehensive documentation support you need to succeed.			</Typography>
			<Box sx={{mt: 4, textAlign: 'center'}}>
					<Button variant='outlined' sx={{mr:4, borderRadius:'20px'}} onClick={() => {
					navigate('/')}}>
					Make a request
					</Button>
					<Button variant='contained' sx={{borderRadius:'20px'}} onClick={() => {
					navigate('/login');}}>Join</Button>
				</Box>
		</Box>
	);
};

export default About;


/* {createSubtitleElement('Who we are')}
<Typography sx={{mb: 5}}>
	We&apos;re building a global marketplace that brings together a diverse array of experts and consultancies across various industries and specialties. Whether you&apos;re searching for a seasoned professional to guide your business strategy or seeking niche expertise for a specific project, our platform empowers you to find the perfect match with ease.
</Typography>
{createSubtitleElement('Connecting You with Global Expertise')}
<Typography sx={{mb: 5}}>
	We&apos;ve curated a network of top-tier experts and consultancies from around the globe, ensuring that you have access to the best talent in every field. We prioritize transparency and security, providing you with detailed profiles, reviews, and secure communication channels to facilitate seamless collaboration. We&apos;re committed to continuously enhancing our platform to meet the evolving needs of our users, incorporating feedback and leveraging cutting-edge technology to deliver an unparalleled experience.
</Typography> */
