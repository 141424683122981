import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {
	Avatar,
	Button,
	Divider,
	IconButton,
	Menu,
	MenuItem,
	Tooltip,
} from '@mui/material';

import {
	Login as LoginIcon,
} from '@mui/icons-material';

import useApiContext from '../ApiContext';

export const LoginWidget: React.FC = () => {
	const [anchorEl, setAnchorEl] = useState<HTMLElement>();
	const {loggedIn, api, user} = useApiContext();
	const navigate = useNavigate();

	const gotoLogin = () => {
		navigate('/login');
	};

	const logout = () => {
		navigate('/logout');
	};

	const handleClose = () => {
		setAnchorEl(undefined);
	};

	if (loggedIn) {
		const username = api.getUsername() ?? '??';
		const initials = username.substring(0, 2).toUpperCase();

		return (
			<>
				<Avatar
					onClick={event => {
						setAnchorEl(event.currentTarget);
					}}
					sx={{
						cursor: 'pointer',
						bgcolor: 'text.primary',
						textDecoration: 'underline',
						ml: 2,
					}}
				>
					{initials}
				</Avatar>
				<Menu
					id='menu-logged-in'
					anchorEl={anchorEl}
					anchorOrigin={{
						vertical: 45,
						horizontal: 'right',
					}}
					keepMounted
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					sx={{
						'& .MuiPaper-root': {
							backgroundColor: 'text.primary',
							color: 'background.paper',
						},
					}}
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					<MenuItem onClick={
						() => {
							navigate('/edit-profile');
							handleClose();
						}
					}>
						Edit your profile
					</MenuItem>
					<MenuItem
						onClick={
							() => {
								if (user?.expertProfile) {
									navigate(`/profile/${user.id}`);
									handleClose();
								}
							}
						}
						disabled={!(user?.expertProfile)}
					>
						View your profile
					</MenuItem>
					<MenuItem onClick={
						() => {
							navigate('/my-org');
							handleClose();
						}
					}>
						{user?.org?.id && (
							<>Edit your organization</>
						)}
						{!user?.org?.id && (
							<>Create your organization</>
						)}
					</MenuItem>
					<MenuItem onClick={
						() => {
							navigate(`/orgs/${user?.org?.id ?? '<not-found>'}`);
							handleClose();
						}
					} disabled={!user?.org?.id}>
						View your organization
					</MenuItem>
					<Divider
						sx={{
							bgcolor: 'background.paper',
						}}
						variant='middle'
					/>
					<MenuItem onClick={
						() => {
							logout();
							handleClose();
						}
					}>
						Sign out
					</MenuItem>
				</Menu>
			</>
		);
	}

	return (
		<>
			<Button
				sx={{
					display: {
						xs: 'none',
						sm: 'block',
					},
					borderColor: 'text.primary',
					border: '1px',
					borderStyle: 'solid',
					borderRadius: '20px',
				}}
				color='inherit'
				onClick={gotoLogin}
			>
				Sign&nbsp;up
			</Button>
			<Tooltip
				title='Sign up'
				sx={{
					display: {
						xs: 'block',
						sm: 'none',
					},
					my: 0,
				}}
			>
				<IconButton
					color='inherit'
					onClick={gotoLogin}
					sx={{
						my: 0,
						display: {
							xs: 'block',
							sm: 'none',
						},
					}}
				>
					<LoginIcon sx={{my: 0}} />
				</IconButton>
			</Tooltip>
		</>
	);
};

export default LoginWidget;
