import {type Dispatch, type SetStateAction} from 'react';

import {type ApiContextType} from './types/ApiContextType';

import {type Config} from './types/Config';

import {
	type Route,
	country,
	filterOrganizations,
	createUser as createUserRoute,
	login as loginRoute,
	getOrganization as getOrganizationRoute,
	createOrg as createOrgRoute,
	getCoops,
	createCoop as createCoopRoute,
	getRegions as getRegionsRoute,
	expertise as getExpertiseRoute,
	getTopics as getTopicsRoute,
	editOrg as editOrgRoute,
	deleteOrg as deleteOrgRoute,
	getCountriesWithRegion as getCountriesWithRegionRoute,
	getProductTypes as getProductTypesRoute,
	createOrder as createOrderRoute,
	resetPasswordSendEmail as resetPasswordSendEmailRoute,
	resetPasswordSetNew as resetPasswordSetNewRoute,
	getLanguages as getLanguagesRoute,
	createOrUpdateExpertProfile as createOrUpdateExpertProfileRoute,
	getUser as getUserRoute,
	toggleExpertMembership as toggleExpertMembershipRoute,
} from '../common/routes';

import {type CountryWithRegion} from './../common/types/CountryWithRegion';
import {type RegionBase} from './../common/types/Region';
import type Country from '../common/types/Country';
import {type Org, type OrgFormData} from '../common/types/Org';
import type Expertise from '../common/types/Expertise';
import type Topic from '../common/types/Topic';
import {type OrgsQuery} from './../common/types/OrgsQuery';
import {type OrgsReply} from './../common/types/OrgsReply';
import type UserCreateData from '../common/types/UserCreateData';
import type User from '../common/types/User';
import type Token from '../common/types/Token';
import {type LoginPayload} from '../common/types/User';
import type Coop from '../common/types/Coop';
import type JwtPayload from '../common/types/JwtPayload';
import RoleType from '../common/types/RoleType';
import type Role from '../common/types/Role';
import {getMessage} from '../common/util';
import {type AuthorizeUser} from '../common/types/JwtPayload';
import type CoopCreateData from '../common/types/CoopCreateData';
import type ProductType from '../common/types/ProductType';
import type Product from '../common/types/Product';
import type Language from '../common/types/Language';
import {type ExpertProfile, type ExpertProfilePayload} from '../common/types/ExpertProfile';
import type ExpertMembershipToggleResult from '../common/types/ExpertMembershipToggleResult';

export type Payload = any;

type AuthListener = (loggedIn: boolean, roles: Role[]) => void;
type ErrorListener = (message: string, res: Response) => void;

const storagePrefix = 'polylat-index';

const getKey = (key: string) => `${storagePrefix}-${key}`;

const saveToSession = (key: string, value: unknown) => {
	sessionStorage.setItem(getKey(key), JSON.stringify(value));
};

const getFromSession = <T>(key: string): T | undefined => {
	const item = sessionStorage.getItem(getKey(key));

	if (!item) {
		return undefined;
	}

	return JSON.parse(item) as T;
};

const deleteFromSession = (key: string) => {
	sessionStorage.removeItem(getKey(key));
};

export const createApi = (config: Config) => {
	const {apiHost: apiRootUrl} = config;
	const errorListeners: ErrorListener[] = [];
	const authListeners: AuthListener[] = [];

	let updateContext: Dispatch<SetStateAction<ApiContextType>> | undefined;

	let authToken = getFromSession<string>('authToken');
	let jwt = getFromSession<AuthorizeUser>('jwt');

	const userIsSuperAdmin = () => {
		console.log('checking if user is super admin...', {jwt});

		if (!jwt) {
			return false;
		}

		return jwt.roles.find(
			r => r.roleType === RoleType.superAdmin,
		) !== undefined;
	};

	const userIsVerified = () => {
		if (!jwt) {
			return false;
		}

		return jwt.roles.find(
			r => r.roleType === RoleType.user,
		) !== undefined;
	};

	const setAuth = (token: string | undefined) => {
		if (token === authToken) {
			return;
		}

		console.log('authenticating...', {token});

		authToken = token;
		jwt = undefined;

		if (!token) {
			deleteFromSession('authToken');
			deleteFromSession('jwt');

			for (const listener of authListeners) {
				listener(false, []);
			}

			updateContext?.(prev => ({
				...prev,
				user: undefined,
				loggedIn: false,
				roles: [],
			}));

			return;
		}

		const decoded = atob(token.split('.')[1]);
		const parsed = JSON.parse(decoded) as JwtPayload;

		if (parsed.action === 'authorize-user') {
			jwt = parsed;
		} else {
			throw new Error('Invalid token action, authorize-user expected');
		}

		saveToSession('authToken', token);
		saveToSession('jwt', jwt);

		for (const listener of authListeners) {
			listener(true, jwt.roles);
		}

		fetchGlobalData().catch(error => {
			console.error('Failed to initialize API', error);
		});
	};

	const makeVerb = (method: 'GET' | 'POST' | 'PUT' | 'DELETE') =>
		(pathSuffix: string) =>
			async <T, U extends Payload = Payload>(payload: U): Promise<T> => {
				const urlObj = new URL(pathSuffix, apiRootUrl);

				if (typeof payload === 'object' && payload !== null) {
					for (const [key, value] of Object.entries(payload)) {
						if (pathSuffix.includes(`:${key}`)) {
							urlObj.pathname = urlObj.pathname.replace(`:${key}`, String(value));
						} else if (method === 'GET') {
							urlObj.searchParams.append(key, String(value));
						}
					}
				}

				const headers: Record<string, string> = {
					'Content-Type': 'application/json',
				};

				if (authToken) {
					headers['x-authorization'] = `Bearer ${authToken}`;
				}

				const res = await fetch(urlObj.href, {
					headers,
					method,
					body: method === 'GET' ? undefined : JSON.stringify(payload),
				});

				if (res.headers.has('x-authorization')) {
					const authorization = res.headers.get('x-authorization');

					if (!authorization) {
						throw new Error('Authorization header is empty');
					}

					const maybeToken = /^Bearer\s+(.*)$/.exec(authorization);

					if (!maybeToken) {
						throw new Error('Invalid Authorization header');
					}

					setAuth(maybeToken[1]);
				}

				const json = (await res.json()) as T;

				if (!res.ok) {
					const error = getMessage(json, 'An unspecified error occurred, sorry about that.');
					for (const listener of errorListeners) {
						listener(error, res);
					}

					if (res.status === 401) {
						setAuth(undefined);
					}

					throw new Error(error);
				}

				return json;
			};

	const verbs = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		GET: makeVerb('GET'),
		// eslint-disable-next-line @typescript-eslint/naming-convention
		POST: makeVerb('POST'),
		// eslint-disable-next-line @typescript-eslint/naming-convention
		PUT: makeVerb('PUT'),
		// eslint-disable-next-line @typescript-eslint/naming-convention
		DELETE: makeVerb('DELETE'),
	};

	const method = <
		ResponseType,
		PayloadType = Payload,
	>(route: Route, onResponse?: (res: ResponseType) => void) => {
		const fn = verbs[route.method](route.path)<ResponseType, PayloadType>;

		return async (payload: PayloadType) => {
			const res = await fn(payload);

			onResponse?.(res);

			return res;
		};
	};

	// D const getExpertiseList = method<Expertise[]>(expertise);
	const getCountries = method<Country[], {zoneCode: number | number[]} | undefined>(country);
	const filterOrgs = method<OrgsReply, OrgsQuery>(filterOrganizations);
	const getOrganization = method<Org, {id: string}>(getOrganizationRoute);

	const createOrg = method<Org, OrgFormData>(createOrgRoute, org => {
		updateContext?.(prev => {
			if (!prev.user) {
				return prev;
			}

			return {
				...prev,
				user: {
					...prev.user,
					org,
				},
			};
		});
	});

	const createUser = method<User, UserCreateData>(createUserRoute);
	const preLogin = method<Token, LoginPayload>(loginRoute);
	const getCoopsList = method<Coop[]>(getCoops);
	const createCoop = method<Coop, CoopCreateData>(createCoopRoute);
	const getRegions = method<RegionBase[]>(getRegionsRoute);
	const getExpertiseList = method<Expertise[]>(getExpertiseRoute);
	const getTopics = method<Topic[]>(getTopicsRoute);
	const getCountriesWithRegion = method<CountryWithRegion[]>(getCountriesWithRegionRoute);
	const getProductTypes = method<ProductType[]>(getProductTypesRoute);
	const createOrder = method<true, {products: Product[]}>(createOrderRoute);
	const resetPasswordSendEmail = method<true, {email: string}>(resetPasswordSendEmailRoute);
	const resetPasswordSetNew = method<true, {password: string; token: string}>(resetPasswordSetNewRoute);
	const getLanguages = method<Language[]>(getLanguagesRoute);

	const createOrUpdateExpertProfile = method<{profile: ExpertProfile; userId: number}, ExpertProfilePayload>(
		createOrUpdateExpertProfileRoute,
		({profile}) => {
			updateContext?.(prev => {
				if (!prev.user) {
					return prev;
				}

				return {
					...prev,
					user: {
						...prev.user,
						expertProfile: profile,
					},
				};
			});
		},
	);
	const getUser = method<User, {id: number} | undefined>(getUserRoute);

	const updateConnectedUser = (user: User) => {
		updateContext?.(prev => ({
			...prev,
			user,
		}));
	};

	const editOrg = method<Org, Partial<OrgFormData>>(editOrgRoute);

	const deleteOrg = async (orgId: number) => method<true>({
		...deleteOrgRoute,
		path: deleteOrgRoute.path.replace(':id', `${orgId}`),
	})(undefined);

	const toggleExpertMembership = method<ExpertMembershipToggleResult, {expertId: number}>(toggleExpertMembershipRoute);

	const login = async (credentials: LoginPayload) => {
		const token = await preLogin(credentials);

		if (token.type !== 'token') {
			throw new Error('Invalid token type');
		}

		setAuth(token.value);

		return token;
	};

	const logout = () => {
		setAuth(undefined);
	};

	const getUsername = () => {
		if (!jwt) {
			return undefined;
		}

		return jwt.username;
	};

	const getUserId = () => {
		if (!jwt) {
			return undefined;
		}

		return jwt.userId;
	};

	const getUserEmail = () => {
		if (!jwt) {
			return undefined;
		}

		return jwt.userEmail;
	};

	const setUpdateContext = (fn: Dispatch<SetStateAction<ApiContextType>>) => {
		updateContext = fn;

		fetchGlobalData().catch(error => {
			console.error('Failed to initialize API', error);
		});
	};

	const fetchGlobalData = async () => {
		if (!authToken || !jwt) {
			return;
		}

		if (!updateContext) {
			return;
		}

		const user = await getUser(undefined);

		if (user) {
			updateContext(prev => ({
				...prev,
				user,
			}));
		}
	};

	const api = {
		filterOrgs,
		createOrg,
		editOrg,
		deleteOrg,
		getOrganization,
		getExpertiseList,
		getCountries,
		createUser,
		getCoopsList,
		createCoop,
		login,
		logout,
		getRegions,
		getProductTypes,
		getTopics,
		getCountriesWithRegion,
		createOrder,
		resetPasswordSendEmail,
		resetPasswordSetNew,
		getLanguages,
		createOrUpdateExpertProfile,
		toggleExpertMembership,
		getUser,
		getUserId,
		updateConnectedUser,
		addErrorListener(handler: ErrorListener) {
			errorListeners.push(handler);
		},
		removeErrorListener(handler: ErrorListener) {
			const index = errorListeners.indexOf(handler);

			if (index !== -1) {
				errorListeners.splice(index, 1);
			}
		},
		addAuthListener(handler: AuthListener) {
			authListeners.push(handler);
			handler(Boolean(authToken), jwt?.roles ?? []);
		},
		removeAuthListener(handler: AuthListener) {
			const index = authListeners.indexOf(handler);

			if (index !== -1) {
				authListeners.splice(index, 1);
			}
		},
		userIsSuperAdmin,
		userIsVerified,
		getUsername,
		getUserEmail,
		setUpdateContext,
	};

	return api;
};

export type Api = ReturnType<typeof createApi>;

export default createApi;
