import React from 'react';
import {useLoaderData, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';

import PlaceIcon from '@mui/icons-material/Place';
import TopicIcon from '@mui/icons-material/Topic';
import EngineeringIcon from '@mui/icons-material/Engineering';
import PhoneIcon from '@mui/icons-material/Phone';
import GroupsIcon from '@mui/icons-material/Groups';
import InfoIcon from '@mui/icons-material/Info';

import {
	Button,
	Box,
	Typography,
	Grid,
	Divider,
	Stack,
} from '@mui/material';

import type Org from '../../common/types/Org';

import {type Api} from '../createApi';
import {getMessage, isError} from '../../common/util';
import useApiContext from '../ApiContext';
import type Error from '../../common/types/Error';
import {type OrgFormEditData} from '../../common/types/Org';
import OrgForm from '../components/OrgForm';
import Image from '../components/Image';
import {ConfirmButton} from '../components/ConfirmButton';
import useLayout from '../hooks/useLayout';
import type ExpertProfile from '../../common/types/ExpertProfile';

const ExpertCard: React.FC<{
	expert: ExpertProfile;
}> = ({expert}) => (
	<Box>
		<Image sources={expert.image}/>
		<Typography variant='h6'><strong>{expert.fullName}</strong></Typography>
		<Typography variant='body1'>{expert.professionalTitle}</Typography>
		<Button variant='outlined' sx={{mt: 2}} disabled>
			Hire
		</Button>
	</Box>
);

export const createLoader = (api: Api) => async ({params}: any): Promise<Org> =>
	api.getOrganization({id: (params.orgId as string)});

export const OrgPage: React.FC = () => {
	useLayout({
		type: "three-column",
		title: undefined,
		left: undefined,
	});

	const [org, setOrg] = React.useState<Org | Error>(useLoaderData() as Org | Error);
	const {api} = useApiContext();
	const navigate = useNavigate();

	const handleSubmit = async (org: OrgFormEditData) => {
		try {
			const newOrg = await api.editOrg(org);
			setOrg(newOrg);
			toast.success('Organization edited successfully.');
		} catch (e) {
			toast.error('Failed to edit organization: ' + getMessage(e, 'unknown error'));
		}
	};

	const handleDelete = async () => {
		if (isError(org)) {
			return;
		}

		try {
			await api.deleteOrg(org.id!);
			toast.success('Organization deleted successfully.');
			navigate('/');
		} catch (e) {
			toast.error('Failed to delete organization: ' + getMessage(e, 'unknown error'));
		}
	};

	if (isError(org)) {
		return (
			<Box>
				<Typography variant='h3'>
					Error
				</Typography>
				<Typography variant='body1'>
					{org.message}
				</Typography>
			</Box>
		);
	}

	const adminPart = api.userIsSuperAdmin() ? (
		<Box>
			<Divider sx={{mt: 16}}/>
			<Typography variant='h5' sx={{my: 2}}>
				Admin Area
			</Typography>

			<OrgForm
				org={org}
				handleSubmit={handleSubmit}
				title='Edit Organization'
				buttonText='Save Updates to this Organization'
				subtitle='You can see this because you are an administrator of this organization.'
				extraButtons={[
					<ConfirmButton
						key='delete'
						label='Delete Organization'
						onClick={handleDelete}
						sx={{
							my: 2,
						}}
					/>,
				]}
			/>
		</Box>
	) : null;

	const experts = org.members.map(member => member.user.expertProfile).filter(Boolean) as ExpertProfile[];

	return (
		<Box>
			<Grid container>
				<Grid item xs={12} md={8}>
					<Box sx={{my: 2,}}>
						<Image sources={org.image} />
					</Box>

					<Typography variant='h4' sx={{my:2}}>
						<strong>{org.name}</strong>
					</Typography>
					<Stack alignItems="center" direction="row" gap={1}>
						<PlaceIcon color ="primary"/>
						<Typography variant='h6' color="primary">
							{org.countries.map(c => c.name).join(' - ')}
						</Typography>
					</Stack>

					<Typography sx={{my:4}}>
						{org.description}
					</Typography>

					<Divider sx={{my: 2}}/>

					<Stack alignItems="center" direction="row" gap={1} sx={{mb:2}}>
						<TopicIcon />
						<Typography variant='h6'>
							<strong>Topic</strong>
						</Typography>
					</Stack>
						{org.topics.map(topic => (
							<Typography key={topic.id}>
								{topic.name}
							</Typography>
						))}

					<Divider sx={{my: 2}}/>

					<Stack alignItems="center" direction="row" gap={1} sx={{mb:2}}>
						<EngineeringIcon />
						<Typography variant='h6'>
						<strong>Expertise</strong>
						</Typography>
					</Stack>
						{org.expertise.map(expertise => (
							<Typography key={expertise.id}>
								{expertise.name}
							</Typography>
						))}

					<Divider sx={{my: 2}}/>

					<Stack alignItems="center" direction="row" gap={1} sx={{mb:2}}>
						<PhoneIcon />
						<Typography variant='h6'>
							<strong>Contact Details</strong>
						</Typography>
					</Stack>

					<Typography variant='body1'>
						Website: www.bze.org.au
					</Typography>

					<Typography variant='body1'>
						email: info@bze.org.au
					</Typography>

					<Typography variant='body1'>
						Twitter: @adada
					</Typography>

					<Typography variant='body1'>
						Address:
						Level 18, 1 Nicholson Street, East Melbourne, Vic 3002, Australia
					</Typography>

					<Typography variant='body1'>
						Business registration number:
						123456789
					</Typography>

					<Divider sx={{my: 2}}/>

					<Stack alignItems="center" direction="row" gap={1} sx={{mb:2}}>
						<InfoIcon />
						<Typography variant='h6'>
							<strong>Information</strong>
						</Typography>
					</Stack>

					<Typography variant='body1'>
						Type of organization:
						NGO
					</Typography>

					<Typography variant='body1'>
						Founder:
						John Doe, PhD in Policy Studies
					</Typography>

					<Typography variant='body1'>
						Founding Year:
						1999
					</Typography>

					<Typography variant='body1'>
						Number of employees:
						250
					</Typography>

					<Typography variant='body1'>
						Annual budget:
						250 000
					</Typography>

					<Divider sx={{my: 2}}/>

					<Stack alignItems="center" direction="row" gap={1} sx={{mb:2}}>
						<GroupsIcon />
						<Typography variant='h6'>
							<strong>Experts</strong>
						</Typography>
					</Stack>

					{experts.length > 0 && (<Box sx={{
						display: 'flex',
						gap: 4,
						flexDirection: 'row',
						alignItems: 'flex-start',
					}}>
						{experts.map(expert => (
							<ExpertCard key={expert.id} expert={expert} />
						))}
					</Box>)}

					<Divider sx={{my: 4}}/>
					<Typography>
							This organization is a proud member of the Polylat network, a global alliance of local knowledge-producing entities. By being part of Polylat, this organization contributes its unique expertise and insights to a collaborative platform that aims to bridge critical knowledge gaps. Together, we provide comprehensive intelligence and advisory services to institutions worldwide, ensuring informed decision-making and effective policy development.
					</Typography>
				</Grid>

				<Grid item xs={12} md={2} sx={{
					display: 'flex',
					justifyContent: 'right',
					ml:6
				}}>
					<Box sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: 2,
						width: 'min-content',
					}}>
						<Button variant='outlined' disabled>
							Form Consortium
						</Button>
						<Button variant='outlined' disabled>
							Send RIP
						</Button>
						<Button variant='outlined' disabled>
							Buy briefing
						</Button>
						<Button variant='outlined' disabled>
							Sell briefing
						</Button>
					</Box>
				</Grid>
			</Grid>
			{adminPart}
		</Box>
	);
};

export default OrgPage;
