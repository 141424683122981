/* eslint-disable @typescript-eslint/naming-convention */

import {createTheme} from '@mui/material/styles';

const ourTheme = createTheme({
	typography: {
		fontFamily: 'Kumbh Sans',
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				body: {
					overflow: 'scroll',
				},
			},
		},
		MuiAppBar: {
			styleOverrides: {
				root: {
					border: 'none',
					boxShadow: 'none',
				},
			},
		},
	},
	palette: {
		primary: {
			main: '#E43C2C',
		},
		text: {
			primary: '#3c486b',
		},
		secondary: {
			main: '#333',
		},
	},
});

export default ourTheme;
