import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {Box, Button, TextField, Typography} from '@mui/material';

import {createFunnelTitleElement} from '../../jsxUtil';
import useApiContext from '../../ApiContext';
import useLayout from '../../hooks/useLayout';

import type ProductType from '../../../common/types/ProductType';
import useCart from '../../CartContext';
import FunnelRecap from '../../components/FunnelRecap';

const ProductButton: React.FC<{
	type: ProductType;
}> = ({type}) => {
	const {current} = useCart();

	const selected = current.type?.id === type.id;

	const borderColor = selected ? 'primary.main' : 'text.primary';
	const borderWidth = 1;
	const backgroundColor = selected ? 'primary.main' : undefined;
	const textColor = selected ? 'white' : undefined;

	return (
		<Box sx={{
			border: 'solid',
			borderWidth,
			borderColor,
			display: 'flex',
			flexDirection: 'column',
			justifyContents: 'space-between',
			p: 1,
			pt: 2,
			pb: 2,
			cursor: 'pointer',
			height: '100%',
			backgroundColor,
			color: textColor,
			borderRadius: '20px',
		}}>
			<Typography sx={{
				textAlign: 'center',
				mb: 1,
			}}>
				<strong>{type.name}</strong>
			</Typography>
			<Typography sx={{
				textAlign: 'center',
				fontSize: '10pt',
			}}>
				{type.description}
			</Typography>
		</Box>
	);
};

export const Funnel1ProductType: React.FC = () => {
	useLayout({
		type: 'three-column',
		title: undefined,
		left: <FunnelRecap />,
	});

	const {api} = useApiContext();
	const cart = useCart();
	const navigate = useNavigate();

	const [productTypes, setProductTypes] = useState<ProductType[]>([]);

	useEffect(() => {
		(async () => {
			try {
				const productTypes = await api.getProductTypes(undefined);
				setProductTypes(productTypes);
			} catch (e) {
				console.error(e);
			}
		})();
	}, []);

	const selected = cart.current.type !== undefined && (cart.current.type.name !== 'Other' || cart.current.otherType);

	const handleClick = (type: ProductType) => () => {
		cart.setType(type);
	};

	const title = createFunnelTitleElement(
		'Choose the type of briefing',
		'1/6 Product',
	);

	const ui = (
		<Box>
			{title}
			<Box sx={{
				display: 'grid',
				gap: 2,
				gridTemplateColumns: {
					xs: 'repeat(1, minmax(0, 1fr));',
					sm: 'repeat(3, minmax(0, 1fr));',
				},
				gridAutoRows: '1fr',
			}}>
				{productTypes.map(type => (
					<Box
						key={type.id}
						onClick={handleClick(type)}
					>
						<ProductButton type={type} />
					</Box>
				))}
			</Box>

			{cart.current.type?.name === 'Other' && (
				<Box sx={{
					my: 4,
				}}>
					<TextField
						placeholder='Please specify your request (max 500 characters)'
						multiline
						minRows={5}
						style={{
							width: '100%',
							padding: 2,
						}}
						onChange={e => {
							cart.setOtherType(e.target.value);
						}}
						value={cart.current.otherType}
					/>
				</Box>
			)}

			<Box sx={{
				mt: 4,
				display: 'flex',
				justifyContent: 'flex-end',
			}}>
				<Button disabled={!selected} variant='contained' color='secondary' sx={{borderRadius: '20px'}} onClick={() => {
					navigate('/product-length');
				}}>
					Next
				</Button>
			</Box>
		</Box>
	);

	return ui;
};

export default Funnel1ProductType;
