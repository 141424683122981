import React, {useState} from 'react';

import {
	Autocomplete,
	Grid,
	TextField,
} from '@mui/material';

import type Expertise from '../../common/types/Expertise';
import type Country from '../../common/types/Country';
import type OrgsQuery from '../../common/types/OrgsQuery';

export const OrgFilter: React.FC<{
	expertise: Expertise[];
	country: Country[];
	initialQuery: OrgsQuery;
	onChange: (query: OrgsQuery) => void;
}> = ({expertise, country, onChange, initialQuery}) => {
	const [query, setQuery] = useState<OrgsQuery>(initialQuery);

	return (
		<Grid container spacing={4} sx={{mb: 4}}>
			<Grid item xs={12} md={6}>
				<Autocomplete
					multiple={true}
					options={expertise}
					getOptionLabel={expertise => expertise.name}
					isOptionEqualToValue={(option, value) =>
						option.id === value.id
					}
					renderInput={params => (
						<TextField name='expertise' label='Expertise' {...params} />
					)}
					onChange={(_, value) => {
						const newQuery = {
							...query,
							expertiseId: value.map(e => e.id!),
						};

						setQuery(newQuery);
						onChange(newQuery);
					}}
					value={
						expertise.filter(e => query.expertiseId.includes(e.id!))
					}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<Autocomplete
					multiple={true}
					options={country}
					getOptionLabel={country => country.name}
					isOptionEqualToValue={(option, value) =>
						option.id === value.id
					}
					renderInput={params => (
						<TextField name='country' label='Country' {...params} />
					)}
					onChange={(_, value) => {
						const newQuery = {
							...query,
							countryId: value.map(c => c.id!),
						};

						setQuery(newQuery);
						onChange(newQuery);
					}}
					value={
						country.filter(c => query.countryId.includes(c.id!))
					}
				/>
			</Grid>
		</Grid>
	);
};

export default OrgFilter;
