import React, {useState, useEffect} from 'react';
import {Form} from 'react-router-dom';

import {
	Autocomplete,
	Box,
	Button,
	TextField,
	Typography,
} from '@mui/material';

import {MuiFileInput} from 'mui-file-input';

import {validate} from 'class-validator';

import {encode} from 'base64-arraybuffer';

import {type Api} from '../createApi';
import CoopCreateData from '../../common/types/CoopCreateData';
import useApiContext from '../ApiContext';
import {type RegionBase} from '../../common/types/Region';

export const createAction = (api: Api) => async ({request}: {request: Request}) => {
	const data = await request.formData();
	console.log('create coop data', data);

	const input = new CoopCreateData();
	input.name = data.get('name') as string;
	input.description = data.get('description') as string;
	input.logoBase64 = data.get('fileLogoBase64') as string;

	const errors = await validate(input);

	if (errors.length > 0) {
		return {
			status: 400,
			body: {
				errors,
			},
		};
	}

	const coop = await api.createCoop(input);

	return coop;
};

export const CoopForm: React.FC = () => {
	const [file, setFile] = useState<File>();
	const [logoBase64, setLogoBase64] = useState<string>('');
	const [logoDataUrl, setLogoDataUrl] = useState<string>('');
	const [regions, setRegions] = useState<RegionBase[]>([]);
	const [regionNumCode, setRegionNumCode] = useState<number>();
	const {api} = useApiContext();

	useEffect(() => {
		if (!file) {
			return;
		}

		const reader = new FileReader();
		const previewReader = new FileReader();

		reader.onload = () => {
			const imageData = reader.result;

			if (!(imageData instanceof ArrayBuffer)) {
				console.error('image data is not an array buffer');
				return;
			}

			setLogoBase64(encode(imageData));
		};

		reader.onerror = console.error;

		previewReader.onload = () => {
			setLogoDataUrl(previewReader.result as string);
		};

		reader.readAsArrayBuffer(file);
	}, [file]);

	useEffect(() => {
		(async () => {
			const regionList = await api.getRegions(undefined);
			setRegions(regionList);
		})();
	}, []);

	const ui = <Box sx={{mb: 2}}>
		<Typography variant='h4'>
			Add a Cooperative
		</Typography>
		<p>
			<small>
				you are seeing this because you have the &quot;super&nbsp;admin&quot; role
			</small>
		</p>
		<Form method='POST'>
			<TextField
				label='Name'
				name='name'
				variant='outlined'
				fullWidth
				sx={{mt: 2}}
			/>

			<TextField
				label='Description'
				name='description'
				variant='outlined'
				fullWidth
				sx={{mt: 2}}
			/>

			<Autocomplete
				options={regions}
				getOptionLabel={region => region.name}
				isOptionEqualToValue={
					(option, value) => option.numCode === value.numCode
				}
				renderInput={params => (
					<TextField
						{...params}
						label='Region'
						variant='outlined'
						fullWidth
						sx={{mt: 2}}
					/>
				)}
				onChange={
					(_event, value) => {
						setRegionNumCode(value?.numCode ?? undefined);
					}
				}
			/>
			<input type='hidden' name='regionNumCode' value={regionNumCode}/>

			<MuiFileInput label='Logo' onChange={v => {
				setFile(v ?? undefined);
			}} value={file} sx={{
				mt: 2,
				display: 'block',
			}} inputProps={{
				name: 'logoFileObject',
				accept: 'image/png, image/gif, image/jpeg, image/webp, image/svg',
			}} fullWidth/>

			<input type='hidden' name='fileLogoBase64' value={logoBase64}/>

			{logoDataUrl && <Box sx={{
				my: 2,
			}}>
				<Typography variant='h6'>
					Logo Preview
				</Typography>
				<Box sx={{
					mt: 2,
				}}>
					<img src={logoDataUrl} alt='logo preview' style={{
						maxWidth: '50%',
						display: 'block',
					}} />
				</Box>
			</Box>}

			<Button sx={{mt: 2}} variant='contained' type='submit'>
				Add Cooperative
			</Button>
		</Form>
	</Box>;

	return ui;
};

export default CoopForm;
