export type Env = 'production' | 'development';

export const env = (): Env => process.env.NODE_ENV === 'production' ? 'production' : 'development';

export const message = (e: unknown): string => {
	if (typeof e === 'string') {
		return e;
	}

	if (e instanceof Error) {
		return e.message;
	}

	return JSON.stringify(e);
};

export const has = (key: string) => (x: unknown): x is Record<string, unknown> =>
	typeof x === 'object' && x !== null && (key in x);

export const isError = (x: unknown): x is {type: 'error'; message?: string} =>
	has('type')(x) && x.type === 'error';

export const isSuccess = (x: unknown): boolean =>
	!isError(x) && Boolean(x);

export const getMessage = (x: unknown, defaultValue: string): string => {
	if (isError(x)) {
		return x.message ?? defaultValue;
	}

	return defaultValue;
};
