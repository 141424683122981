import React, {useState, useEffect} from 'react';
import {
	Box,
	IconButton,
	TextField,
	Typography,
	Paper,
	type SxProps,
} from '@mui/material';

import {Add as AddIcon, Delete as DeleteIcon} from '@mui/icons-material';

type Item = {
	key: number;
	value: string;
};

export const ArrayInput: React.FC<{
	sx?: SxProps;
	label: string;
	value: string[];
	onChange: (newValue: string[]) => void;
	minimumRequired?: number;
}> = ({sx, value, label, onChange, minimumRequired = 0}) => {
	const [items, doSetItems] = useState<Item[]>([]);
	const [newItem, setNewItem] = useState('');

	const setItems = (newItems: Item[]) => {
		doSetItems(newItems);

		const newValue = newItems.map(i => i.value);

		onChange(newValue);
	};

	useEffect(() => {
		const newItems = value.map((v, i) => ({key: i, value: v}));

		setItems(newItems);
	}, [value]);

	const inputRequired = (i: number) => {
		if (i < minimumRequired) {
			return true;
		}

		return false;
	};

	const addRequired = () => {
		if (items.length < minimumRequired) {
			return true;
		}

		return false;
	};

	const ui = (
		<Paper sx={{p: 2, ...sx, boxShadow: 'none'}} >
			<Typography sx={{
				mb: 1,
			}}>
				<strong>{label}</strong>
			</Typography>

			{items.map((item, i) => (
				<Box
					key={item.key}
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						gap: 1,
					}}
				>
					<TextField
						required={inputRequired(i)}
						value={item.value}
						onChange={e => {
							const newItems = items.map(i => {
								if (i.key === item.key) {
									return {
										...i,
										value: e.target.value,
									};
								}

								return i;
							});

							setItems(newItems);
						}}
						fullWidth
						sx={{
							mb: 1,
						}}
					/>
					<IconButton
						onClick={() => {
							const newItems = items.filter(i => i.key !== item.key);

							setItems(newItems);
						}}
					>
						<DeleteIcon />
					</IconButton>
				</Box>
			))}

			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					gap: 1,
				}}
			>
				<TextField
					required={addRequired()}
					label='New item'
					fullWidth
					value={newItem}
					onChange={e => {
						setNewItem(e.target.value);
					}}
				/>
				<IconButton
					onClick={() => {
						const newItems = [...items, {key: items.length, value: newItem}];

						setNewItem('');
						setItems(newItems);
					}}
				>
					<AddIcon />
				</IconButton>
			</Box>
		</Paper>
	);

	return ui;
};

export default ArrayInput;
