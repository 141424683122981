import React from 'react';
import {createRoot} from 'react-dom/client';

import {createApi} from './createApi';
import {createApp} from './createApp';

import config from '../../config.client';

if (process.env.API_HOST) {
	config.apiHost = process.env.API_HOST;
}

const target = document.getElementById('app');

if (target) {
	const api = createApi(config);
	const App = createApp(api);

	const root = createRoot(target);
	root.render(
		<React.StrictMode>
			<App />
		</React.StrictMode>,
	);
} else {
	throw new Error('No #app element');
}
