import React, {useEffect} from 'react';
import {useNavigate, Form, Link} from 'react-router-dom';

import {createTitleElement} from '../jsxUtil';
import {Box, Button, TextField, Typography} from '@mui/material';

import {type Api} from '../createApi';
import useApiContext from '../ApiContext';
import useCart from '../CartContext';
import useLayout from '../hooks/useLayout';

export type LoginProps = {
	email: string;
	password: string;
	onEmailChange: (email: string) => void;
	onPasswordChange: (password: string) => void;
};

export const createAction = (api: Api) => async ({request}: {request: Request}) => {
	const formData = await request.formData();

	const email = formData.get('email') as string;
	const password = formData.get('password') as string;

	console.log('login action', [email, '<password>']);

	try {
		return await api.login({email, password});
	} catch (e) {
		return null;
	}
};

export const Login: React.FC<LoginProps> = ({
	email,
	password,
	onEmailChange,
	onPasswordChange,
}) => {
	useLayout({
		type: 'three-column',
		title: undefined,
		left: undefined,
	});

	const {loggedIn} = useApiContext();
	const navigate = useNavigate();
	const {products} = useCart();

	useEffect(() => {
		if (!loggedIn) {
			return;
		}

		if (products.length > 0) {
			navigate('/requests');
		} else {
			navigate('/');
		}
	}, [loggedIn]);

	const form = (
		<Form method='POST'>
			<TextField
				label='E-mail or Username'
				name='email'
				autoComplete='username email'
				variant='outlined'
				fullWidth
				required
				sx={{
					mb: 2,
				}}
				value={email}
				onChange={e => {
					onEmailChange(e.target.value);
				}}
			/>

			<TextField
				label='Password'
				name='password'
				autoComplete='current-password'
				variant='outlined'
				type='password'
				fullWidth
				required
				sx={{
					mb: 2,
				}}
				value={password}
				onChange={e => {
					onPasswordChange(e.target.value);
				}}
			/>

			<Box sx={{
				display: 'flex',
				justifyContent: 'center',
				gap: 2,
			}}>
				<Button
					variant='outlined'
					color='primary'
					onClick={() => {
						navigate('/register');
					}}
				>
					Register instead
				</Button>

				<Button
					variant='contained'
					color='primary'
					type='submit'
				>
					Login
				</Button>
			</Box>

			<Box sx={{textAlign: 'right', mt: 8}}>
				<Typography color='primary'>
					<Link to='/forgot-password' style={{
						color: 'inherit',
					}}>
						Forgot your password?
					</Link>
				</Typography>
			</Box>
		</Form>
	);

	return (
		<div>
			{createTitleElement('Login')}
			{form}
		</div>
	);
};

export default Login;
