import React, {useEffect} from 'react';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';

import {
	Box,
	// D Grid,
} from '@mui/material';

import {Container} from '@mui/material';
import {toast} from 'react-toastify';

import useApiContext from '../ApiContext';

import Footer from './Footer';
// D import FunnelRecap from './FunnelRecap';
import NavBar from './NavBar';

import LayoutOutlet from './LayoutOutlet';

export const Layout: React.FC = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [urlSearchParams] = useSearchParams();
	const {api} = useApiContext();

	/* D
	const isFunnelPage = location.pathname === '/'
		|| location.pathname === '/product-length'
		|| location.pathname === '/country'
		|| location.pathname === '/topic'
		|| location.pathname === '/expertise'
		|| location.pathname === '/additional-info';
	*/

	useEffect(() => {
		const urlMessage = urlSearchParams.get('message');
		const messageType = urlSearchParams.get('messageType');

		if (urlMessage) {
			const type = messageType === 'error'
				? 'error'
				: messageType === 'success'
					? 'success'
					: 'info';

			toast(urlMessage, {type});
		}
	}, [location]);

	useEffect(() => {
		const listener = (_m: string, res: Response) => {
			if (res.status === 401) {
				navigate('/login');
			}
		};

		api.addErrorListener(listener);

		return () => {
			api.removeErrorListener(listener);
		};
	}, []);

	return (
		<Box sx={{
			display: 'grid',
			gridTemplateRows: 'auto 1fr auto',
			minHeight: '100vh',
		}}>
			<NavBar />
			<Container maxWidth={false}>
				<ToastContainer
					position='top-center'
					style={{
						display: 'block',
					}}
				/>
				<LayoutOutlet />
			</Container>
			<Footer />
		</Box>
	);
};

export default Layout;

