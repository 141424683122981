import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {Box, Button, TextField} from '@mui/material';

import {createFunnelTitleElement} from '../../jsxUtil';
import useApiContext from '../../ApiContext';
import useCart from '../../CartContext';

import type Topic from '../../../common/types/Topic';

import UnrolledSelect from '../../components/UnrolledSelect';
import FunnelRecap from '../../components/FunnelRecap';
import useLayout from '../../hooks/useLayout';

export const Funnel4Topic: React.FC = () => {
	useLayout({
		type: 'three-column',
		title: undefined,
		left: <FunnelRecap />,
	});

	const cart = useCart();
	const {api} = useApiContext();
	const navigate = useNavigate();
	const [topics, setTopics] = useState<Topic[]>();

	useEffect(() => {
		(async () => {
			try {
				const t = await api.getTopics(undefined);
				setTopics(t);
			} catch (e) {
				console.error(e);
			}
		})();
	}, []);

	const selected = cart.current.topic !== undefined && (cart.current.topic.name !== 'Other' || cart.current.otherTopic);

	const title = createFunnelTitleElement(
		'What is the general topic?',
		'4/6 Topic',
	);

	const ui = (
		<Box>
			{title}

			<UnrolledSelect
				items={topics ?? []}
				onSelect={topic => {
					cart.setTopic(topic);
				}}
				selected={cart.current.topic}
			/>

			{cart.current.topic?.name === 'Other' && (
				<Box sx={{
					my: 4,
				}}>
					<TextField
						placeholder='Please specify your request (max 500 characters)'
						multiline
						minRows={5}
						style={{
							width: '100%',
							padding: 2,
						}}
						onChange={e => {
							cart.setOtherTopic(e.target.value);
						}}
						value={cart.current.otherTopic}
					/>
				</Box>
			)}

			<Box sx={{
				mt: 4,
				display: 'flex',
				justifyContent: 'flex-end',
				gap: 2,
			}}>
				<Button
					variant='text'
					sx={{
						textDecoration: 'underline',
					}}
					color='secondary'
					onClick={
						() => {
							navigate('/country');
						}
					}
				>
					Back
				</Button>
				<Button disabled={!selected} variant='contained' color='secondary' sx={{borderRadius: '20px'}} onClick={
					() => {
						navigate('/expertise');
					}
				}>
					Next
				</Button>
			</Box>
		</Box>
	);

	return ui;
};

export default Funnel4Topic;
