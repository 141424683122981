import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {Box, Button, Typography, Slider} from '@mui/material';

import {createFunnelTitleElement} from '../../jsxUtil';

import useCart from '../../CartContext';
import FunnelRecap from '../../components/FunnelRecap';
import useLayout from '../../hooks/useLayout';

type PresetSize = {
	name: string;
	fromPages: number;
	toPages: number;
	selected?: true;
};

const sizes: PresetSize[] = [
	{name: 'Standard', fromPages: 1, toPages: 4},
	{name: 'Detailed', fromPages: 4, toPages: 10},
	{name: 'Multi', fromPages: 10, toPages: 20},
	{name: 'Jumbo', fromPages: 20, toPages: 30},
	{name: 'Mega', fromPages: 30, toPages: 40},
];

const min = sizes[0].fromPages;
const max = 100;

const defaultCustomSize: PresetSize = {
	name: 'Custom',
	fromPages: sizes[sizes.length - 1].toPages,
	toPages: max,
};

const sizeMatches = (fromPages?: number, toPages?: number) => (size: PresetSize) => {
	if (fromPages === undefined || toPages === undefined) {
		return false;
	}

	return size.fromPages <= fromPages && toPages <= size.toPages;
};

const LengthButton: React.FC<{
	size: PresetSize;
}> = ({size}) => {
	const {current} = useCart();

	const selected = size.selected ?? sizeMatches(current.fromPages, current.toPages)(size);

	const backgroundColor = selected ? 'primary.main' : undefined;
	const borderColor = selected ? 'primary.main' : 'text.primary';
	const textColor = selected ? 'white' : undefined;
	const borderWidth = 1;

	return (
		<Box sx={{
			border: '1px solid',
			borderColor,
			borderWidth,
			backgroundColor,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			padding: 2,
			cursor: 'pointer',
			color: textColor,
			height: '100%',
			borderRadius: '20px',
		}}>
			<Typography><strong>{size.name}</strong></Typography>
			<Typography>
				{size.fromPages}&nbsp;&#8209;&nbsp;{size.toPages}&nbsp;pages
			</Typography>
		</Box>
	);
};

export const Funnel2ProductLength: React.FC = () => {
	useLayout({
		type: 'three-column',
		title: undefined,
		left: <FunnelRecap />,
	});

	const cart = useCart();
	const [customSize, setCustomSize] = useState(defaultCustomSize);
	const navigate = useNavigate();

	const handleClick = (size: PresetSize) => () => {
		cart.setPages(size.fromPages, size.toPages);
	};

	const matchingSize = sizeMatches(cart.current.fromPages, cart.current.toPages);
	const oneSizeMatches = sizes.some(matchingSize);

	useEffect(() => {
		if (oneSizeMatches) {
			setCustomSize(defaultCustomSize);
		} else if (cart.current.fromPages !== undefined && cart.current.toPages !== undefined) {
			setCustomSize({
				...defaultCustomSize,
				fromPages: cart.current.fromPages,
				toPages: cart.current.toPages,
				selected: true,
			});
		}
	}, [oneSizeMatches, cart.current.fromPages, cart.current.toPages]);

	const title = createFunnelTitleElement(
		'Choose the length',
		'2/6 Length',
	);

	const selected = cart.current.fromPages !== undefined && cart.current.toPages !== undefined;

	const ui = (
		<Box>
			{title}
			<Box
				sx={{
					display: 'grid',
					gap: 2,
					gridTemplateColumns: 'repeat(3, minmax(0, 1fr));',
					gridAutoRows: '1fr',
					mb: 6,
				}}
			>
				{[...sizes, customSize].map(size => (
					<Box
						key={size.name}
						onClick={handleClick(size)}
						sx={{
							flex: '1 1 0px',
						}}
					>
						<LengthButton
							size={size}
						/>
					</Box>
				))}
			</Box>

			<Slider
				sx={{
					mb: 4,
				}}
				value={[
					cart.current.fromPages ?? min,
					cart.current.toPages ?? max,
				]}
				onChange={(_event, value) => {
					if (Array.isArray(value)) {
						cart.setPages(value[0], value[1]);
					}
				}}
				valueLabelDisplay='auto'
				aria-labelledby='range-slider'
				getAriaValueText={value => `${value} pages`}
				min={min}
				max={max}
			/>

			{!selected && (
				<Box>
					<Typography>
						<strong>
							Please select a size above or use the slider to select a custom size.
						</strong>
					</Typography>
				</Box>
			)}

			{selected && (
				<Box>
					<Typography>
						{cart.current.type ? `${cart.current.type.name}: ` : null}
						<strong>
							{cart.current.fromPages ?? min} - {cart.current.toPages ?? max} pages
						</strong>
					</Typography>
				</Box>
			)}

			<Box sx={{
				mt: 4,
				display: 'flex',
				justifyContent: 'flex-end',
				gap: 2,
			}}>
				<Button
					variant='text'
					sx={{
						textDecoration: 'underline',
					}}
					color='secondary'
					onClick={
						() => {
							navigate('/');
						}
					}
				>
					Back
				</Button>
				<Button disabled={!selected} variant='contained' color='secondary' sx={{borderRadius: '20px'}} onClick={
					() => {
						navigate('/country');
					}
				}>
					Next
				</Button>
			</Box>
		</Box>
	);

	return ui;
};

export default Funnel2ProductLength;
