import React from 'react';

import {
	Typography,
	Link,
} from '@mui/material';

/* Old part of footer:

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

<Box
	sx={{
		display: 'flex',
		flexDirection: 'column',
		px: 32,
		gap: 4,
		mt: 4,
		py: 4,
		backgroundColor: 'primary.main',
	}}
>
	<Typography
		variant='h3'
	>
		Connecting You <br />
		with Global <br />
		Expertise <ChevronRightIcon/>
	</Typography>
	<Typography
		sx={{
			alignSelf: 'flex-end',
			maxWidth: 600,
		}}
	>
		We&apos;ve curated a network of top-tier experts and consultancies from around the globe,
		ensuring that you have access to the best talent in every field.
		We prioritize transparency and security,
		providing you with detailed profiles, reviews,
		and secure communication channels to facilitate seamless collaboration.
	</Typography>

	<Typography
		sx={{
			alignSelf: 'flex-end',
			maxWidth: 600,
		}}
	>
		We&apos;re committed to continuously enhancing our platform to meet the evolving needs of our users,
		incorporating feedback and leveraging cutting-edge technology to deliver an unparalleled experience.
	</Typography>

	<Typography variant='h4' sx={{alignSelf: 'center'}}>
		Partners
	</Typography>

	<Grid container justifyContent='center' spacing={2} sx={{alignSelf: 'center'}}>
		{squares.map((square, index) => (
			<Grid item key={index} xs={12} sm={6} md={3}>
				<Box
					sx={{
						width: square.c,
						height: square.c,
						backgroundColor: 'secondary.main',
						maxWidth: square.c,
					}}
				/>
			</Grid>
		))}
	</Grid>
</Box>
*/

export const Footer: React.FC = () => {
	const squares: Array<{c: number}> = [];

	for (let i = 0; i < 12; ++i) {
		squares.push({c: 150});
	}

	/* D
	const ui = (
		<Grid container sx={{
			p: 4,
		}}>
			<Grid
				item
				xs={12}
				sm={3}
			>
				<Typography fontSize={20}>
					<strong>Polylat</strong>
				</Typography>
			</Grid>
			<Grid
				item
				xs={12}
				sm={6}
			>
				<Typography sx={{pl: 4}}>
					Intelligence requests to independent local advisories — anywhere, on-demand
				</Typography>
			</Grid>
			<Grid
				item
				xs={12}
				sm={3}
			>
				<Box sx={{
					display: 'flex',
					flexDirection: 'row',
					gap: 2,
				}}>
					<Box>
						<Typography>
							About
						</Typography>
						<Typography>
							Contact
						</Typography>
						<Typography>
							Twitter
						</Typography>
					</Box>
					<Box>
						<Typography>
							Terms&nbsp;&&nbsp;Conditions
						</Typography>
						<Typography>
							Privacy
						</Typography>
					</Box>
				</Box>
			</Grid>
		</Grid>
	);
	*/

	const ui = (
		<Typography textAlign='center' sx={{my: 4, mx: 2}}>
			<strong>Polylat </strong>
			<Link href='mailto:info@polylat.org'>info@polylat.org</Link>. All rights reserved. <Link href='https://f467trbiklb.infomaniak.site'>Privacy Policy</Link>. <Link href='https://f467trbiklb.infomaniak.site/terms-of-use'>Terms of use.</Link> |  Created and hosted in 🇨🇭 Switzerland
		</Typography>
	);

	return ui;
};

export default Footer;
