import React, {useState} from 'react';
import {Button, Box, type SxProps} from '@mui/material';

export const ConfirmButton: React.FC<{
	label: string;
	onClick: () => void;
	sx?: SxProps;
}> = ({onClick, label, sx}) => {
	const [confirming, setConfirming] = useState(false);
	if (confirming) {
		return (
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-start',
					alignItems: 'center',
					gap: 2,
					mx: 2,
					...sx,
				}}
			>
				<Button
					variant='outlined'
					color='error'
					onClick={onClick}
				>
					Confirm
				</Button>
				<Button
					variant='outlined'
					color='primary'
					onClick={
						() => {
							setConfirming(false);
						}
					}
				>
					Cancel
				</Button>
			</Box>
		);
	}

	return (
		<Button
			variant='outlined'
			color='warning'
			onClick={
				() => {
					setConfirming(true);
				}
			}
		>
			{label}
		</Button>
	);
};
