import type ErrorMessage from '../common/types/Error';

export const isNotError = <T>(value: unknown): value is T => {
	const isE = isError(value);

	return !isE;
};

export const isError = (value: unknown): value is ErrorMessage => {
	if (typeof value !== 'object' || value === null) {
		throw new Error('non null object expected');
	}

	if (!('type' in value)) {
		return false;
	}

	if (value.type === 'error') {
		return true;
	}

	return false;
};

const storagePrefix = 'polylat-index-';

export const getFromLocalStorage = <T>(key: string, defaultValue?: T): T | undefined => {
	const fullKey = `${storagePrefix}${key}`;

	const value = localStorage.getItem(fullKey);

	if (!value) {
		return defaultValue ?? undefined;
	}

	return JSON.parse(value) as T;
};

export const saveToLocalStorage = (key: string, value: unknown) => {
	const fullKey = `${storagePrefix}${key}`;

	localStorage.setItem(fullKey, JSON.stringify(value));
};

export const deleteFromLocalStorage = (key: string) => {
	const fullKey = `${storagePrefix}${key}`;

	localStorage.removeItem(fullKey);
};

let counter = getFromLocalStorage<number>('counter', 0) ?? 0;
export const getId = () => {
	counter += 1;
	saveToLocalStorage('counter', counter);

	return counter;
};

export const shuffleArray = <T>(array: T[]): T[] => {
	const newArray = [...array];
	for (let i = newArray.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[newArray[i], newArray[j]] = [newArray[j], newArray[i]];
	}

	return newArray;
};

export const isAcceptablePassword = (password: string) => {
	if (password.length < 4) {
		return false;
	}

	return true;
};
