import React from 'react';
import {Box, Typography, type SxProps} from '@mui/material';
// D import {ChevronRight as ChevronRightIcon} from '@mui/icons-material';

export const titleMb = 6;

export const createChevronTitle = (title: string, sx?: SxProps) => (
	<Typography variant='h4' sx={{
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'flex-end',
		gap: 1,
		mb: titleMb,
		fontWeight: 'bold',
		lineHeight: 1,
		...sx,
	}}>
		{title}
		<Typography color='primary' variant='h5' component='span'><strong>&gt;</strong></Typography>
	</Typography>
);

// <ChevronRightIcon color='primary' sx={{lineHeight: 0}} fontSize='inherit' />

export const createTitleElement = (title: string, mb = titleMb) => (
	<Typography variant='h4' sx={{
		mb,
		textAlign: 'left',
	}}>
		{title}
	</Typography>
);

export const subtitleMb = 4;

export const createSubtitleElement = (subtitle: string, mb = subtitleMb) => (
	<Typography variant='h5' sx={{
		mb,
		textAlign: 'left',
	}}>
		{subtitle}
	</Typography>
);

export const createFunnelTitleElement = (title: string, additional: string) => {
	const titleElt = createTitleElement(title, 0);

	return (
		<Box sx={{
			display: 'flex',
			gap: 2,
			alignItems: 'center',
			justifyContent: 'space-between',
			flexWrap: 'wrap',
			mb: titleMb,
		}}>
			{titleElt}

			<Typography color='primary.main'>{additional}</Typography>
		</Box>
	);
};
