import type AuthFunction from './types/AuthFunction';
import RoleType from './types/RoleType';

export const isSuperAdmin: AuthFunction = roles => roles.map(r => r.roleType).includes(RoleType.superAdmin);

export const isVerifiedUser: AuthFunction = roles => {
	const res = roles.map(r => r.roleType).includes(RoleType.user);

	return res;
};

export const isAnyUser: AuthFunction = () => true;
