import React from 'react';
import {useNavigate} from 'react-router-dom';

import {Box, Typography, IconButton, Button, Card, Divider} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import useCart from '../CartContext';
import {createChevronTitle} from '../jsxUtil';

import useApiContext from '../ApiContext';

import {type Product} from '../../common/types/Product';
import {toast} from 'react-toastify';
import useLayout from '../hooks/useLayout';

const lineTitle = (p: Product) => {
	if (p.type.name === 'Other') {
		return `Special Request to Polylat ${p.country.region_name}`;
	}

	return `${p.type.name}, Polylat ${p.country.region_name}`;
};

const ProductCard: React.FC<{product: Product}> = ({product}) => {
	const {removeFromCart} = useCart();

	const ui = (
		<Card key={product.id} sx={{
			mt: 4,
			mb: 2,
			padding: 2,
			border: '1px solid',
			borderRadius: '20px',
			boxShadow: 0,
		}}>
			<Typography variant='h6' sx={{
				ml: 2,
				mt: 1,
			}}>
				<strong>
					{lineTitle(product)}
				</strong>
			</Typography>
			<Box sx={{
				mt: 2,
				pl: 2,
			}}>
				<Typography sx={{my: 2}}>
					<Typography component='span' color='primary'><strong>Country</strong></Typography>&nbsp;-&nbsp;{product.country.country_name}
				</Typography>
				<Typography sx={{my: 2}}>
					<Typography component='span' color='primary'><strong>Topic</strong></Typography>&nbsp;-&nbsp;{product.topic.name}
				</Typography>
				{product.topic.name === 'Other' && (
					<Box sx={{
						p: 2,
					}}>
						<code>
							{product.otherTopic}
						</code>
					</Box>
				)}
				<Typography sx={{my: 2}}>
					<Typography component='span' color='primary'><strong>Expertise</strong></Typography>&nbsp;-&nbsp;{product.expertise.name}
				</Typography>
				{product.expertise.name === 'Other' && (
					<Box sx={{
						p: 2,
					}}>
						<code>
							{product.otherExpertise}
						</code>
					</Box>
				)}
				<Typography sx={{my: 2}}>
					<Typography component='span' color='primary'><strong>Pages</strong></Typography>&nbsp;-&nbsp;from&nbsp;{product.fromPages}&nbsp;to&nbsp;{product.toPages}
				</Typography>
			</Box>
			<Box sx={{
				display: 'flex',
				direction: 'row',
				justifyContent: 'flex-end',
				alignItems: 'center',
				gap: 1,
			}}>
				<IconButton
					aria-label='delete'
					onClick={() => {
						removeFromCart(product);
					}}
					sx={{
						// eslint-disable-next-line @typescript-eslint/naming-convention
						'&:hover': {
							backgroundColor: 'transparent',
						},
					}}
				>
					<DeleteIcon />
					<Typography sx={{textDecoration: 'underline'}}>Delete this request</Typography>
				</IconButton>
			</Box>
		</Card>
	);

	return ui;
};

const pluralize = (n: number) => (s: string) => {
	if (n === 1) {
		return s;
	}

	return s + 's';
};

export const Cart: React.FC = () => {
	const title = createChevronTitle('Your requests');

	useLayout({
		type: 'three-column',
		title,
		left: undefined,
	});

	const {products, clearCart} = useCart();
	const {loggedIn, roles, api} = useApiContext();
	const navigate = useNavigate();

	const isEmailVerified = roles.length > 0;

	const actionText = 'Request a quote';

	const submitOrder = async () => {
		try {
			await api.createOrder({products});

			toast.success(
				products.length === 1
					? 'Your request has been submitted successfully.'
					: 'Your requests have been submitted successfully.',
			);
			clearCart();
		} catch (e) {
			toast.error('There was an error submitting your request. Please try again later.');
		}
	};

	return (
		<Box>
			{products.length > 0 && (
				<Typography sx={{mb: 6}}>
					You have <strong>{products.length} {pluralize(products.length)('request')}</strong> ready to be submitted to our network of experts. Make sure everything looks perfect before proceeding to confirm.
				</Typography>
			)}

			{products.length === 0 && (
				<Typography
					color='primary'
					sx={{
						mb: 6,
						cursor: 'pointer',
						textDecoration: 'underline',
					}}
					onClick={() => {
						navigate('/');
					}}
				>
					You do not have any requests to submit currently, make a new one?
				</Typography>
			)}

			{products.map(product => <ProductCard key={product.id} product={product} />)}

			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					gap: 2,
					mt: 6,
					mx: 4,
				}}
			>
				{loggedIn && isEmailVerified && <Box>
					<Button
						variant='contained'
						onClick={submitOrder}
						sx={{
							borderRadius: '20px',
							mb: 4,
						}}
						disabled = {products.length === 0}
					>
						{actionText}
					</Button>
				</Box>}

				{!loggedIn && <Box>
					<Button variant='contained' onClick={() => {
						navigate('/login');
					}}>
						Log&nbsp;in to {actionText.toLowerCase()}
					</Button>
				</Box>}

				{loggedIn && !isEmailVerified && <Box sx={{
					textAlign: 'right',
				}}>
					<Typography sx={{mb: 2}}>
						<strong>Please verify your e-mail address to request a quote.</strong>
					</Typography>
					<Button disabled variant='contained'>
						{actionText}
					</Button>
				</Box>}
			</Box>
			<Box sx={{my: 4}}>
				<Typography variant='h6' sx={{my: 4}}>
					<strong>Safe and Confidential </strong>
				</Typography>
				<Typography>Your requests and information are kept secure and confidential. We prioritize your privacy and data protection. Privacy is our priority. Hosted in Switzerland, our platform ensures your requests and information are kept secure and confidential, following strict data protection standards.</Typography>
				<Divider sx={{my: 2}}></Divider>
				<Typography variant='h6' sx={{my: 4}}>
					<strong>Need Assistance?</strong>
				</Typography>
				<Typography>If you have any questions or need help with making your request, please contact us at index@polylat.org or call 1-800-123-4567.</Typography>
				<Divider sx={{my: 2}}></Divider>
				<Typography sx={{my: 2}}>Thank you for trusting us with your requests!</Typography>
			</Box>
		</Box>
	);
};

export default Cart;
