import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';

import {
	Box,
	CircularProgress,
	Divider,
	Typography,
	Link,
	Button,
	Chip,
	Grid,
	type ButtonProps,
	type GridProps,
	type TypographyProps,
	Avatar,
} from '@mui/material';

import {
	BusinessCenter as BusinessCenterIcon,
	ContactMail as ContactMailIcon,
	Place as PlaceIcon,
	Timelapse as TimelapseIcon,
	Flag as FlagIcon,
	Language as LanguageIcon,
	type SvgIconComponent,
} from '@mui/icons-material';

import useApiContext from '../ApiContext';
import useLayout, {type Layout} from '../hooks/useLayout';

import {titleMb, createChevronTitle} from '../jsxUtil';

import Image from '../components/Image';
import type User from '../../common/types/User';

const IconLine: React.FC<{
	icon: SvgIconComponent;
	element: string | React.ReactElement;
}> = ({icon: IconComponent, element}) => (
	<Box sx={{
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: 1,
	}}>
		<IconComponent color='primary' fontSize='medium' />
		{(typeof element === 'string') && <Typography sx={{m: 0, lineHeight: 0}} fontSize='medium'>
			{element}
		</Typography>}
		{(typeof element !== 'string') && element}
	</Box>
);

const SmallTitle: React.FC<{
	title: string;
	sx?: TypographyProps;
	color?: string;
}> = ({title, sx, color}) => (
	<Typography variant='h4' sx={{mb: 1, ...sx}} fontSize='small' color={color}>
		{title}
	</Typography>
);

const Chips: React.FC<{
	labels: string[];
}> = ({labels}) => (
	<Box sx={{
		display: 'flex',
		flexDirection: 'row',
		gap: 1,
		flexWrap: 'wrap',
	}}>
		{labels.map(label => (
			<Chip
				key={label}
				label={label}
				color='primary'
				variant='outlined'
				size='small'
			/>
		))}
	</Box>
);

const ExperienceSection: React.FC<{
	title: string;
	lines: string[];
}> = ({title, lines}) => {
	const titleElt = <SmallTitle title={title} color='primary' />;

	return (
		<Box>
			{titleElt}
			<Box sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: 1,
			}}>
				{lines.map(line => (
					<Typography key={line} fontSize='small'>
						{line}
					</Typography>
				))}
			</Box>
		</Box>
	);
};

const ExperienceGridItem: React.FC<{
	title: string;
	lines: string[];
}> = ({title, lines}) => {
	const gridColumnProps: GridProps = {
		item: true,
		xs: 12,
		sm: 4,
	};

	return (
		<Grid {...gridColumnProps}>
			<ExperienceSection title={title} lines={lines} />
		</Grid>
	);
};

type ExperienceSectionData = {
	title: string;
	lines: string[];
};

export const ExpertProfilePage: React.FC = () => {
	const {id: rawId} = useParams();
	const userId = (Number(rawId));
	const {api, user: connectedUser} = useApiContext();

	const [profileUser, setProfileUser] = useState<User>();

	const profile = profileUser?.expertProfile;

	useEffect(() => {
		if (!userId) {
			return;
		}

		(async () => {
			try {
				console.log('Getting expert profile from API', {userId});
				const user = await api.getUser({id: userId});

				if (!user.expertProfile) {
					return;
				}

				setProfileUser(user);
			} catch (error) {
				const message = error instanceof Error
					? error.message
					: 'An error occurred while fetching the expert profile';

				console.error(message, {error});
				toast.error(message);
			}
		})();
	}, [userId]);

	const makeLayout = (): Layout => {
		if (!profile) {
			return {
				type: 'wide',
				title: undefined,
			};
		}

		const title = (
			<Box sx={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				gap: 2,
				mb: titleMb,
			}}>
				<Image
					sources={profile.image}
					alt={profile.fullName}
					style={{height: '70px'}}
					defaultElement={
						<Avatar variant='square' sx={{height: '70px', width: '70px'}}>
							{profile.fullName.substring(0, 4)}
						</Avatar>
					}
				/>
				<Box sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: 1,
					mb: 0,
				}}>
					{createChevronTitle(profile.fullName, {mb: 0})}

					<Box sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'flex-start',
						alignItems: 'center',
						gap: 1,
					}}>
						<BusinessCenterIcon color='primary' fontSize='small' />
						<Typography variant='subtitle1' sx={{m: 0, lineHeight: 0}}>
							{profile.professionalTitle}
						</Typography>
					</Box>
				</Box>
			</Box>
		);

		return {
			type: 'wide',
			title,
		};
	};

	const setLayout = useLayout(makeLayout());

	useEffect(() => {
		setLayout(makeLayout());
	}, [profile]);

	if (!profile) {
		return (
			<Box sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '100%',
			}}>
				<CircularProgress />
			</Box>
		);
	}

	const expertBelongsToMyOrg = () => {
		if (!connectedUser) {
			return false;
		}

		if (!connectedUser.org) {
			return false;
		}

		return profileUser.orgs.some(userToOrg => userToOrg.org.id === connectedUser.org!.id);
	};

	const belongsToMyOrg = expertBelongsToMyOrg();

	const toggleExpertMembership = async () => {
		const {updatedOrgMembers, updatedExpertOrgs} = await api.toggleExpertMembership({expertId: profileUser.id!});

		api.updateConnectedUser({
			...connectedUser!,
			org: {
				...connectedUser!.org!,
				members: updatedOrgMembers,
			},
		});

		setProfileUser({
			...profileUser,
			orgs: updatedExpertOrgs,
		});
	};

	const threeColumnsSectionProps: GridProps = {
		container: true,
		spacing: {
			xs: 4,
			sm: 2,
		},
	};

	const gridColumnProps: GridProps = {
		item: true,
		xs: 12,
		sm: 4,
	};

	const columnContentsStyle = {
		display: 'flex',
		flexDirection: 'column',
		gap: 2,
		width: {
			xs: 'fit-content',
			sm: 'auto',
		},
	};

	const buttonProps: ButtonProps = {
		variant: 'contained',
		color: 'primary',
		disabled: true,
		sx: {
			flex: '1 1 0',
			minWidth: 0,
		},
	};

	const topSection = (
		<Box sx={{
			display: {
				xs: 'flex',
				sm: 'block',
			},
			flexDirection: 'column',
			alignItems: {
				xs: 'center',
				sm: 'flex-start',
			},
		}}>
			<Grid {...threeColumnsSectionProps}>
				<Grid {...gridColumnProps}>
					<Box sx={{...columnContentsStyle}}>
						<Box>
							<Typography variant='h4' sx={{mb: 1}} fontSize='small'>
								Primary affiliation
							</Typography>
							<Typography>
								{profile.primaryAffiliation}
							</Typography>
						</Box>

						{profile.otherAffiliations.length > 0 && (
							<Box>
								<Typography variant='h4' sx={{mb: 1}} fontSize='small'>
									Other affiliations
								</Typography>
								<Typography>
									{profile.otherAffiliations.join(', ')}
								</Typography>
							</Box>
						)}
					</Box>
				</Grid>

				<Grid {...gridColumnProps}>
					<Box sx={{...columnContentsStyle}}>
						<IconLine icon={ContactMailIcon} element={
							<Link href={`mailto:${profileUser.email}`} sx={{
								fontSize: 'medium',
								textDecoration: 'none',
							}}>
								{profileUser.email}
							</Link>
						}/>
						<IconLine icon={PlaceIcon} element={
							`${profile.countryOfResidence.name}, ${profile.cityOfResidence}`
						}/>
						<IconLine icon={TimelapseIcon} element={profile.timeZone} />
						<IconLine icon={FlagIcon} element={profile.languages[0].name} />
						{(profile.languages.length > 1) && (
							<IconLine icon={LanguageIcon} element={
								profile.languages.slice(1).map(lang => lang.name).join(', ')
							}/>
						)}
					</Box>
				</Grid>

				<Grid {...gridColumnProps}>
					<Box sx={{
						...columnContentsStyle,
						alignSelf: {
							xs: 'center',
							sm: 'initial',
						},
						width: 'fit-content',
					}}>
						{!belongsToMyOrg && (<Button
							{...buttonProps}
							disabled={!connectedUser?.org}
							onClick={toggleExpertMembership}
						>
							Add to your organization
						</Button>)}
						{belongsToMyOrg && (<Button
							{...buttonProps}
							disabled={false}
							onClick={toggleExpertMembership}
						>
							Remove from your organization
						</Button>)}
						<Button {...buttonProps}>
							Hire
						</Button>
						<Button {...buttonProps}>
							Other
						</Button>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);

	const topSectionDetails = (
		<Grid {...threeColumnsSectionProps}>
			<Grid {...gridColumnProps}>
				<SmallTitle title='Areas of expertise' />
				<Chips labels={profile.areasOfExpertise.map(e => e.name)} />
			</Grid>

			<Grid {...gridColumnProps}>
				<SmallTitle title='Topics of focus' />
				<Chips labels={profile.topicsOfFocus.map(t => t.name)} />
			</Grid>

			<Grid {...gridColumnProps}>
				<SmallTitle title='Research interests' />
				<Chips labels={profile.researchInterests} />
			</Grid>
		</Grid>
	);

	const rawExperienceSectionData: ExperienceSectionData[] = [
		{
			title: 'Professional experience',
			lines: profile.professionalExperience,
		},
		{
			title: 'Education',
			lines: profile.education,
		},
		{
			title: 'Certifications',
			lines: profile.certifications,
		},
		{
			title: 'Publications',
			lines: profile.publications,
		},
		{
			title: 'Awards and honors',
			lines: profile.awardsAndHonors,
		},
		{
			title: 'Professional memberships',
			lines: profile.professionalMemberships,
		},
		{
			title: 'Speaking engagements',
			lines: profile.speakingEngagements,
		},
		{
			title: 'Patents',
			lines: profile.patents,
		},
		{
			title: 'Testimonials',
			lines: profile.testimonials,
		},
		{
			title: 'Clients list',
			lines: profile.clientList,
		},
		{
			title: 'References',
			lines: profile.references,
		},
	];

	const nonEmptyExperienceData = rawExperienceSectionData.filter(data => data.lines.length > 0);

	const experienceGridItems: React.ReactElement[] = [];

	let added = 0;
	for (const data of nonEmptyExperienceData) {
		experienceGridItems.push(
			<ExperienceGridItem key={data.title} title={data.title} lines={data.lines} />,
		);

		++added;

		if (added === 2) {
			experienceGridItems.push(<Grid item key={`${data.title}-skip`} xs={12} sm={4} />);
			added = 0;
		}
	}

	const bottomSection = (
		<Box>
			<Typography variant='h5' sx={{
				mb: titleMb,
			}}>
				Experience
			</Typography>
			<Grid {...threeColumnsSectionProps}>
				{experienceGridItems}
			</Grid>
		</Box>
	);

	const ui = (
		<Box sx={{
			display: 'flex',
			flexDirection: 'column',
			gap: 6,
		}}>
			{topSection}
			{topSectionDetails}
			<Divider />
			{bottomSection}
		</Box>
	);

	return ui;
};

ExpertProfilePage.displayName = 'ExpertProfilePage';

export default ExpertProfilePage;
