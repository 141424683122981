import React from 'react';
import {Link} from 'react-router-dom';

import {
	Button,
	Card,
	CardContent,
	Typography,
	CardActions,
	CardMedia,
	Box,
} from '@mui/material';

import type Coop from '../../common/types/Coop';
import Image from './Image';

export const CoopCard: React.FC<{
	coop: Coop;
}> = ({coop}) => (
	<Card sx={{
		height: '',
		position: 'relative',
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'column',
	}}>
		<CardMedia>
			<Image
				sources={coop.logo}
				alt={coop.name}
				style={{
					maxWidth: '100%',
				}}
			/>
		</CardMedia>
		<Box>
			<CardContent>
				<Typography variant='h6'>
					{coop.name}
				</Typography>
				<Typography variant='body2'>
					{coop.description}
				</Typography>
			</CardContent>
			<CardActions>
				<Link
					to={`/coops/${coop.id}`}
				>
					<Button
						size='small'
						color='primary'
						variant='outlined'
						type='button'
					>
						More info
					</Button>
				</Link>
			</CardActions>
		</Box>
	</Card>
);

export default CoopCard;
